import store from '@/store'
import * as Types from '@/store/action-types'
import { getLiveDetails } from "@/api"
import { loganLog } from "@/utils/log"


// 防抖
  export function throttle(fn: any, wait: number) {
    let timer: any = null;
    return function () {
      // @ts-ignore
      let context = this;
      let args = arguments;
      if (!timer) {
        timer = setTimeout(function () {
          fn.apply(context, args);
          timer = null;
        }, wait);
      }
    };
  }

// 媒体设备授权
export function mediaDeviceAuthorize() {
    return navigator.mediaDevices.getUserMedia({ video: false, audio: true })
        .then(async (getMicAuthor) => {
            let micTracks = getMicAuthor.getTracks();
            micTracks.forEach(track => track.stop());

            return await navigator.mediaDevices.getUserMedia({ video: true, audio: false })
                .then(getCameraAuthor=>{
                    let cameraTracks = getCameraAuthor.getTracks();
                    cameraTracks.forEach(track => track.stop());
                    // 更新授权状态
                    store.commit(`live/${Types.SET_DEVICE_AUTHORIZE_STATUS}`, { microphone: true, camera: true })
                    return true
                })
                .catch(()=>{
                    return false
                })
        })
        .catch(()=> {
            return false
        })
}

export function isString(str: any) {
    if (typeof str === 'string' || str instanceof String) {
        return true
    }
    return false
}

// 获取地址栏参数
export function getUrlParms(name: String) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null)
        return unescape(r[2]);
    return null;
}

//时间戳转日期
export function getTimeToDate(n: string) {
    let now = new Date(n),
        y = now.getFullYear(),
        m = now.getMonth() + 1,
        d = now.getDate();
    return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d) + " " + now.toTimeString().substr(0, 8);
}

// 获取网络状态
export function _getwNetwork() {
    return new Promise((resolve) => {
        WeiXin.getNetworkType({
            success: function (res:any) {
                console.log('调用成功----', res)
                let wType = 'wifi'
                if (res.networkType !== 'wifi') {
                    wType = 'cellular'
                }
                localStorage.setItem('networkType', wType)
                resolve(wType)
            },
            fail: function (res:any) {
                console.log('调用失败----', res)
                localStorage.setItem('networkType', 'unknown')
                resolve('wx.getNetworkType调用失败')
            }
        })
    })
}

// 轮循详情接口更新背景皮肤-->更新导播台混流模版
let timer = null as any;
export function sbPollingDetailsUpdateTemplate(callBack:Function){
    timer = setTimeout( async (_callBack = callBack)=>{
        try {
            let { data } = await getLiveDetails({ id: store.state.live.liveVideoId });

            loganLog(data, '轮询到的直播详情数据');
            _callBack && _callBack(data);

            if(timer) clearTimeout(timer);
            sbPollingDetailsUpdateTemplate(_callBack);
        } catch (error) {
            loganLog(JSON.stringify(error), '轮询详情接口失败');
            if(timer) clearTimeout(timer);
            sbPollingDetailsUpdateTemplate(_callBack);
        }
    },5000)
}