const envConfig = {
    dev:{
        baseUrl:'https://collect.dev.100ytt.com',
        switcherUrl: 'https://switcher.100url.cn/api',
        reportLogUrl: 'https://meeting-dev-api.100url.cn/ybmeet-log/logan/web/upload.json',
        switcherSocketUrl: 'wss://live.100tt.com.cn/switcher-callback-server'
    },
    test:{
        baseUrl:'https://collect.test.100ytt.com',
        switcherUrl: 'https://switcher.test.100mix.cn/api',
        reportLogUrl: 'https://api.test.100meet.cn/ybmeet-log/logan/web/upload.json',
        switcherSocketUrl: 'wss://switcher.test.100mix.cn/switcher-callback-server'
    },
    prod:{
        baseUrl:'https://collect.100ytt.com',
        switcherUrl: 'https://www.100mix.cn/api',
        reportLogUrl: 'https://api.100meet.cn/ybmeet-log/logan/web/upload.json',
        switcherSocketUrl: 'wss://www.100mix.cn/switcher-callback-server'
    }
}

export const baseUrl = (envConfig as any)[process.env.VUE_APP_ENV].baseUrl;
export const switcherUrl = (envConfig as any)[process.env.VUE_APP_ENV].switcherUrl;
export const reportLogUrl = (envConfig as any)[process.env.VUE_APP_ENV].reportLogUrl;
export const switcherSocketUrl = (envConfig as any)[process.env.VUE_APP_ENV].switcherSocketUrl;
export const videoUrl = (envConfig as any)[process.env.VUE_APP_ENV].videoUrl;