
import { SDK_ERROR, MAX_SETTING_CACHE_COUNT, NETWORK_TEST_URL } from '../../constant'
import { ResultError } from '../../utils/entity'
import { isArray, isNumber, isString } from '../../utils/check'
import {
  initLocalSetInfo,
  getLocalSetInfo,
  setLocalSetInfo
} from '../../utils/set'

import { signalUrl } from '../../config'
import Base from '../BasePlugin'
import RoomClient from '@ybmeet/yb_rtc_sdk'

class Settings extends Base {
  constructor(app, options) {
    super(app);
    this.roomClient = app.roomClient
    //初始化本地配置缓存
    initLocalSetInfo()
  }

  /** 配置相关 -- start */

  /**
   * 设置配置项
   */
  setConfig(key, value) {
    return new Promise((resolve, reject) => {
      if (!isString(key) || !key) {
        const error = new ResultError(
          'PARAMETERS',
           SDK_ERROR.PARAMETERS,
          'setConfig: parameter error')
        reject(error)
        return
      }

      //缓存最大上限限制
      const setCacheInfo = getLocalSetInfo()
      if (Object.keys(setCacheInfo).length === MAX_SETTING_CACHE_COUNT) {
        const error = new ResultError(
          'OUT_OF_RANGE',
           SDK_ERROR.OUT_OF_RANGE,
          'setConfig: out of range for max limit of setting count')
        reject(error)
      }

      setLocalSetInfo(
        {
          [key]: value
        }
      )
      resolve()
    })
  }

  /**
   * 获取配置项
   * return value
   */
  getConfig(key) {
    return new Promise(async (resolve, reject) => {
      if (!isString(key) || !key) {
        const error = new ResultError(
          'PARAMETERS',
           SDK_ERROR.PARAMETERS,
          'getConfig: parameter error')
        reject(error)
        return
      }

      let setCacheInfo = getLocalSetInfo()
      if (!setCacheInfo || Object.keys(setCacheInfo).length  === 0) {
        initLocalSetInfo() //初始化本地配置缓存
        setCacheInfo = getLocalSetInfo()
      }
      //获取不到local数据返回空
      
      if (!setCacheInfo.hasOwnProperty(key)) {
        resolve('')
      }else{
        resolve(setCacheInfo[key])
      }
    })
  }

   /** 配置相关 -- end */

  /**
 * 获取麦克风设备列表
 * @param {*} skipPermissionCheck 是否跳过权限检查
 */
  getMicDevicesList(skipPermissionCheck = true) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!skipPermissionCheck) {//权限检测
          //await this.roomClient.devicePermissionCheck(0) //0 audio 1 video
          await this.devicePermissionCheck(0)
        }

        const list = await this.roomClient.getMicDevicesList()
        resolve(list)
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  }

  devicePermissionCheck(mediaType = 0) {
    return new Promise(async (resolve, reject) => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: mediaType === 1,
          audio: true,
        });

        stream.getTracks().forEach((track) => track.stop());
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
 * 获取当前麦克风设备
 */
  getCurrentMicDevice() {
    return new Promise(async (resolve, reject) => {
      try {
        const deviceId = await this.roomClient.getCurrentMic()
        resolve(deviceId)
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * 设置当前麦克风设备
   */
  setCurrentMicDevice(deviceId) {
    return new Promise(async (resolve, reject) => {
      if (!deviceId) {
        const error = new ResultError(
          'PARAMETERS',
           SDK_ERROR.PARAMETERS,
          'setCurrentMicDevice: parameter error')
        reject(error)
        return
      }
      try {
         await this.roomClient.setCurrentMic(deviceId)

         //更新缓存配置设备信息
         await this.setConfig('audioDeviceId', deviceId)

         resolve()
      } catch (error) {
         console.error(error)
         reject(error)
      }
    })
  }

  /**
   * 设置当前麦克风音量
   * @param {*} volume 音量大小，取值0 - 100
   * @returns
   */
  setCurrentMicDeviceVolume(volume) {
    return new Promise(async (resolve, reject) => {
      if (!isNumber(volume) || volume > 100 || volume < 0) {
        const error = new ResultError(
          'PARAMETERS',
           SDK_ERROR.PARAMETERS,
          'setCurrentMicDeviceVolume: parameter error')
        reject(error)
        return
      }

      try {
        await this.roomClient.setAudioCaptureVolume(volume)

        //更新缓存配置设备信息
        await this.setConfig('audioValue', volume)

        resolve()
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  }

  /**
   * 获取当前麦克风音量
   * @returns volume 音量大小，取值0 - 100
   */
  getCurrentMicDeviceVolume() {
    return new Promise(async (resolve, reject) => {
      try {
        const audioVolumn = this.roomClient.getAudioCaptureVolume()
        resolve(audioVolumn)
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * 获取扬声器设备列表
  */
  getSpeakerDevicesList() {
    return new Promise(async (resolve, reject) => {
      try {
        const list = await this.roomClient.getSpeakerDevicesList()
        resolve(list)
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * 获取当前扬声器设备
   */
  getCurrentSpeakerDevice() {
    return new Promise(async (resolve, reject) => {
      try {
        const deviceId = await this.roomClient.getCurrentSpeaker()
        resolve(deviceId)
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
 * 设置当前扬声器设备
 * elements 绑定的audio元素
 */
  setCurrentSpeakerDevice(deviceId, elements = []) {
    return new Promise(async (resolve, reject) => {
      if (!deviceId || !elements) {
        const error = new ResultError(
          'PARAMETERS',
           SDK_ERROR.PARAMETERS,
          'setCurrentSpeakerDevice: parameter error')
        reject(error)
        return
      }

      await this.roomClient.setCurrentSpeaker(deviceId, elements)

      //更新缓存配置设备信息
      await this.setConfig('speakerDeviceId', deviceId)

      resolve()//device
    })
  }

  /**
   * 获取当前扬声器音量
   * @returns volume 音量大小，取值0 - 100
  */
  getCurrentSpeakerDeviceVolume() {
    return new Promise(async (resolve, reject) => {
      try {
        const playoutVolumn = this.roomClient.getAudioPlayoutVolume()
        resolve(playoutVolumn)
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * 设置当前扬声器音量
   * @param {*} volume 音量大小，取值0 - 100
   */
  setCurrentSpeakerDeviceVolume(elements = [], volume) {
    return new Promise(async (resolve, reject) => {
      if (!elements || !isNumber(volume) || volume > 100 || volume < 0) {
        const error = new ResultError(
          'PARAMETERS',
           SDK_ERROR.PARAMETERS,
          'setCurrentSpeakerDeviceVolume: parameter error')
        reject(error)
        return
      }

      try {
        await this.roomClient.setAudioPlayoutVolume(elements, volume)

        //更新缓存配置设备信息
        await this.setConfig('speakerValue', volume)

        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * 获取摄像头列表
   * @param {*} skipPermissionCheck 是否跳过权限检查
   */
  getCameraDevicesList(skipPermissionCheck = true) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!skipPermissionCheck) {//权限检测
          //await this.roomClient.devicePermissionCheck(1) //0 audio 1 video
          await this.devicePermissionCheck(1)
        }

        const list = await this.roomClient.getCameraDeviceList()
        resolve(list)
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * 获取摄像头分辨率列表
   * @param {*} skipPermissionCheck 是否跳过权限检查
   */
   getConstrinsSupport(skipPermissionCheck = true) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!skipPermissionCheck) {//权限检测
          //await this.roomClient.devicePermissionCheck(1) //0 audio 1 video
          await this.devicePermissionCheck(1)
        }

        const list = await this.roomClient.getConstrinsSupport()
        resolve(list)
      } catch (error) {
        reject(error)
      }
    })
  }

  
  /**
   * 获取当前摄像头
   */
  getCurrentCameraDevice() {
    return new Promise(async (resolve, reject) => {
      try {
        const deviceId = await this.roomClient.getCurrentCamera()
        resolve(deviceId)
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * 设置当前摄像头
   * @param {*} deviceId 设备ID
   */
  setCurrentCameraDevice(deviceId) {
    return new Promise(async (resolve, reject) => {
      if (!deviceId) {
        const error = new ResultError(
          'PARAMETERS',
           SDK_ERROR.PARAMETERS,
          'setCurrentCameraDevice: parameter error')
        reject(error)
        return
      }
      try {
         await this.roomClient.setCurrentCameraDevice(deviceId)

         //更新缓存配置设备信息
         await this.setConfig('videoDeviceId', deviceId)

         resolve()
      } catch (error) {
         reject(error)
      }
    })
  }

  /**
   * 设置本地视频编码参数
   *
   * videoResolution: QRTCVideoResolution, //分辨率
	   resMode, //横屏0 竖屏1
	   videoFps: number,
	   minVideoBitrate: number,
	   maxVideoBitrate: number,
	   enableAdjustRes?: boolean //是否允许SDK动态调整分辨率,开启后会对云端录制产生影响,需要开启云端录制的场景建议设置为 NO，中途视频分辨率发生变化后，云端录制出的 MP4 在一般的播放器上都无法正常播放。
	   //视频通话模式，若无需云端录制，可以设置为 YES，此时 SDK 会根据当前待带宽情况自动选择合适的分辨率（仅针对 TRTCVideoStreamTypeBig 生效）
   * @returns
   */
  setEncoderParam(encoderParam) {
    return new Promise(async (resolve, reject) => {
      if (!encoderParam) {
        const error = new ResultError(
          'PARAMETERS',
           SDK_ERROR.PARAMETERS,
          'setEncoderParam: parameter error')
        reject(error)
        return
      }
      try {
        await this.roomClient.setVideoEncoderParam(encoderParam)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * 网络检测
   * @returns
   */
  startNetworkTest() {
    return new Promise(async (resolve, reject) => {
      try {
        // 本地网络连接
        const localConnection = await RoomClient.testHttpConnection(NETWORK_TEST_URL)
        const localNetworkTime = Math.floor(localConnection.pingTime)

        //服务器连接
        const serverUrl = 'https://' + signalUrl.replace('/signal-server', '') + '/test/test.png'
        const serverConnection = await RoomClient.testHttpConnection(serverUrl)
        const serverNetworkTime = Math.floor(serverConnection.pingTime)

        // if (serverNetworkTime < 0 || localNetworkTime < 0) { //-1 超时 异常
        // } else if (serverNetworkTime > 0 && serverNetworkTime < 100) { //正常 良好
        // } else if (serverNetworkTime >= 100 && serverNetworkTime < 300) { //较差
        // } else if (serverNetworkTime >= 300) { //极差
        // }

        resolve({
          localNetworkTime,
          serverNetworkTime
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  /*********** 麦克风检测  start ************/
  /**
   * 麦克风检测开启
   */
  enableMicTest() {
    return new Promise(async (resolve, reject) => {
      try {
        await RoomClient.enableMicTest()

        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * 麦克风检测关闭
   * @returns
   */
  disableMicTest() {
    return new Promise(async (resolve, reject) => {
      try {
        await RoomClient.disableMicTest()

        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * 获取麦克风音量范围
   * @returns
   */
  getMicTestVolume() {
    return new Promise(async (resolve, reject) => {
      try {
        const volumn = RoomClient.getMicTestVolume()

        resolve(volumn)
      } catch (error) {
        reject(error)
      }
    })
  }
  /*********** 麦克风检测  end ************/

  /**
   * 销毁
   */
  destory() {}
}


export default Settings