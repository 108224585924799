import axios from 'axios'
import qs from 'qs'
import { getToken, setToken, getClientId, getClientSecret, getRefreshToken, getUuid, setUuid, getAcceptLanguage, getConferenceToken, getChannelToken, getConferenceNo } from "./auth"
import { meetServerUrl, middleServerUrl } from '../config/index'
import { oauthLogin } from '../api/meet'
import { loganLog } from "../utils/log"

import cryptoRandomString from 'crypto-random-string'

// create an axios instance
const Request = axios.create({
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000, // request timeout
})

// request interceptor
Request.interceptors.request.use(
  config => {
    const conferenceToken = getConferenceToken()
    const channelToken = getChannelToken()

    let conferenceNo = '';
    const searchStr = window.location.search;
    console.error(`----location.search: ${searchStr}----`);
    if (searchStr) {
      const parseQuery = qs.parse(searchStr, { ignoreQueryPrefix: true })
      conferenceNo = parseQuery.conferenceNo
    }
    console.error(`----location.search conferenceNo: ${conferenceNo}; getConferenceNo(): ${getConferenceNo()}----`);
    // do something before request is sent
    config.headers['Accept-Language'] = getAcceptLanguage(); // 多语言
    config.headers["Authorization"] = getToken() ? `Bearer ${getToken()}` : '';

    config.headers["deviceId"] = getUuid() || setUuid(cryptoRandomString({ length: 10 }));

    config.headers["X-Conference-Token"] = conferenceToken || '';
    config.headers["X-Channel-Token"] = channelToken || '';
    config.headers["X-Conference-No"] = conferenceNo || getConferenceNo() || '';

    /**
     * /middle-auth: 中台api
     * 其他为会议服务api
     */
    const url = config.url
    if (url.indexOf('/middle-auth') > -1) {
      config.url = middleServerUrl + url
    } else {
      config.url = meetServerUrl + url
    }

    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)


let isRefreshing = false
let subscribers = []

function onAccessTokenFetched(newToken) {
  subscribers.forEach((callback) => {
    callback(newToken)
  })
  subscribers = []
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

// response interceptor
Request.interceptors.response.use(
  response => {
    return new Promise((resolve, reject) => {
      const resData = response.data
      const { url, isBlobType } = response.config
  
      // 优化，没有token的情况
      if (resData.code === 401 && !url.includes('login/oauth2/token')) {
  
        console.log('触发了401--------')
        loganLog(`Request请求错误-----error: ${JSON.stringify(resData)}`)
  
        if (!isRefreshing) {
          isRefreshing = true
  
          // 将刷新token的方法放在vuex中处理了, 可见下面区块代码
          console.error(`刷新token了 ${getToken()}`)
          console.error(`refreshToken ${getRefreshToken()}`)
          oauthLogin({
            grant_type: 'refresh_token',
            client_id: getClientId(),
            client_secret: getClientSecret(),
            refresh_token: getRefreshToken()
          }).then((res) => {
            console.log('restoken调用成功了----')
            loganLog('restoken调用成功了----')
            console.log(res)
  
            setToken(res.accessToken)
            onAccessTokenFetched(res.accessToken)
            isRefreshing = false
          }).catch((error) => {
            // 刷新token报错了
            error.error_type = 'refresh_token_error'
            console.error(error)
            console.error('刷新token失败了---')
            loganLog('刷新token失败了---')
            isRefreshing = false
            reject(error)
          })
        }
  
        // 将其他接口缓存起来 -- 这个Promise函数很关键
        //   // 这里是将其他接口缓存起来的关键, 返回Promise并且让其状态一直为等待状态, 
        //   // 只有当token刷新成功后, 就会调用通过addSubscriber函数添加的缓存接口, 
        //   // 此时, Promise的状态就会变成resolve
        addSubscriber((newToken) => {
          console.log('即将重新发起请求---')

          loganLog('即将重新发起请求---')

          response.config.headers.Authorization = `Bearer ${newToken || getToken()}`;

          if (url.indexOf('/middle-auth') > -1) {
            console.log('命中中台---');
            response.config.url = response.config.url.replace(middleServerUrl, '')
          } else {
            console.log('命中会议api---')
            response.config.url = response.config.url.replace(meetServerUrl, '')
          }
          
          console.log(response.config)

          // 用重新封装的config去请求, 就会将重新请求后的返回
          resolve(Request(response.config))
        });

      }
  
      if (isBlobType) {
        resolve(resData)
      }
  
      if (resData.code === 200) { // 请求成功
        resolve(resData.data)
        
      } else { // 请求失败
        reject(response.data)
        loganLog(`Request请求错误-----error: ${JSON.stringify(response.data)}`)
      }
    })
  },
  error => {
    loganLog(`Request请求错误-----error: ${JSON.stringify(error)}`)
    return Promise.reject(error)
  }
)

export default Request