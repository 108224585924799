import { YB_TOKEN } from '@/typings/ybrtc';
import Cookies from 'js-cookie'

const channelTokenKey = YB_TOKEN.imToken;
const conferenceTokenKey = YB_TOKEN.meetToken;
const currencyTokenKey = YB_TOKEN.currencyToken;

// 设置通用Token 调导播台API
export function setCurrencyToken (token:string) {
    localStorage.setItem(currencyTokenKey, token)
    Cookies.set('ybToken', token)
}
// 获取通用Token
export function getCurrencyToken () {
    return localStorage.getItem(currencyTokenKey)
}

// 设置IM token
export function setChannelToken (token:string) {
    localStorage.setItem(channelTokenKey, token)
}
// 获取IM token
export function getChannelToken () {
    return localStorage.getItem(channelTokenKey)
}

// 设置会议服务token
export function setConferenceToken (token:string) {
    localStorage.setItem(conferenceTokenKey, token)
}
// 获取会议服务token
export function getConferenceToken () {
    return localStorage.getItem(conferenceTokenKey)
}

/**
 * 存储会议相关信息
 * @param {*} meetToken 
 * @param {*} imToken 
 */
 export function setMeetInfo(imToken:string, meetToken:string) {
    // setChannelToken(imToken)
    setConferenceToken(meetToken)
}