import Logan from "logan-web"
import { isString } from '@/utils/tools'

/**
 * 日志记录
 * @param {*} msg 日志内容
 * @param {*} keywords  定义关键字方便日志查询 多个关键字竖线分割
 * @param {*} type 
 */
export function loganLog(msg:any, keywords?:string, type:number = 1) {
  msg = !isString(msg) ? JSON.stringify(msg) : msg
  // type: 日志来源 2: 来自SDK

  if(type != 2) console.log(msg, keywords); // SDK 会输出到 控制台

  Logan.log(`[h5_log_report] ${keywords ? '[' + keywords + ']' : '' } ${msg}`, type)
}
