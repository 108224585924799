
/********************************** meet-command ************************************/
// 设置媒体设备授权状态
export const SET_DEVICE_AUTHORIZE_STATUS = 'SET_DEVICE_AUTHORIZE_STATUS';
// 设置媒体设备开启状态
export const SET_MEDIA_DEVICE_STATUS = 'SET_MEDIA_DEVICE_STATUS';
// 设置布局模版
export const SET_LAYOUT_TEMPLATE = 'SET_LAYOUT_TEMPLATE';
// 设置直播状态
export const SET_LIVE_STATUS = 'SET_LIVE_STATUS';
// 设置演示文档列表数据
export const SET_DOC_DEMONSTRATE_LIST = 'SET_DOC_DEMONSTRATE_LIST';
// 设置文档演示状态
export const SET_DOC_STATUS = 'SET_DOC_STATUS';
// 更新当前文档演示地址
export const UPDATE_CURRENT_DEMON_DOC = 'UPDATE_CURRENT_DEMON_DOC';
// 更新IM弹幕显隐状态
export const UPDATE_BABERRAGE_VISIBLE = 'UPDATE_BABERRAGE_VISIBLE';
// 更新音频通道打开状态
export const UPDATE_AUDIO_AISLE = 'UPDATE_AUDIO_AISLE';
// 设置导播台（混流后）推流地址
export const SET_PUSH_STREAM_URL = 'SET_PUSH_STREAM_URL';
// 设置直播任务Id
export const SET_LIVE_TASK_ID = 'SET_LIVE_TASK_ID';
// 设置直播项目Id
export const SET_LIVE_PROJECT_ID = 'SET_LIVE_PROJECT_ID';
// 设置直播用户Id
export const SET_LIVE_USER_ID = 'SET_LIVE_USER_ID';
// 底部工具栏显示隐藏状态
export const SET_COMMAND_BAR_VISIABLE = 'SET_COMMAND_BAR_VISIABLE';
// 直播状态初始化操作禁用锁
export const SET_DISABLED_LOCK = 'SET_DISABLED_LOCK';
// 设置当前所演示的文档ID
export const SET_CURRENT_DEMO_DOC_ID = 'SET_CURRENT_DEMO_DOC_ID';

/********************************** meet-info ************************************/
// 更新会议信息
export const SET_MEET_INFO = 'SET_MEET_INFO'
// 设置直播详情信息---saas业务接口返回数据
export const SET_LIVE_INFO = 'SET_LIVE_INFO'

/********************************** user-info ************************************/
// 更新用户信息
export const SET_USER_INFO = 'SET_USER_INFO'

/********************************** switcher-bypass ************************************/
// 设置导播台Id
export const SET_SWITCHER_ID = 'SET_SWITCHER_ID'
// 设置RTC源组件Id
export const SET_RTC_COMPONENT_ID = 'SET_RTC_COMPONENT_ID'
// 设置PPT图片源组件Id
export const SET_OFFICE_IMG_COMPONENT_ID = 'SET_OFFICE_IMG_COMPONENT_ID'
// 设置字幕背景图片源组件Id
export const SET_WORD_BG_IMG_COMPONENT_ID = 'SET_WORD_BG_IMG_COMPONENT_ID'
// 设置输出源组件Id
export const SET_OUTPUT_COMPONENT_ID = 'SET_OUTPUT_COMPONENT_ID'
// 设置模版组件Id
export const SET_TEMPLATE_COMPONENT_ID = 'SET_TEMPLATE_COMPONENT_ID'
// 设置CG字幕源组件Id
export const SET_CG_COMPONENT_ID = 'SET_CG_COMPONENT_ID'
// 设置CG字幕实例Id
export const SET_CG_OBJECT_ID = 'SET_CG_OBJECT_ID'
// 清除导播台数据状态
export const CLEARN_SWITCHER_STATE = 'CLEARN_SWITCHER_STATE'
// 麦克风音量大小
export const SET_VOLUME_SIZE = 'SET_VOLUME_SIZE'
// 是否禁止使用move事件
export const SET_DIS_TOUCHMOVE = 'SET_DIS_TOUCHMOVE'



