import { Im } from './sdk/im';
import IM_EVENT from "./api/im-events";


class YbIm {
    constructor(options) {
    this.EVENT = IM_EVENT
        this.imSDK = new Im(options)
    }

    /**
     * socket连接
     */
    connectSocket(roomId, token){
        this.imSDK.connectSocket(roomId, token)
    }

    /**
     * 获取用户列表
     * @returns {Promise<unknown>}
     */
    getUserList(roomId){
        return this.imSDK.getUserList(roomId)
    }

    /**
     * 获取房间信息
     * @returns {Promise<unknown>}
     */
    getRoomInfo(roomId){
        return this.imSDK.getRoomInfo(roomId)
    }

    /**
     * 获取房间热度
     * @returns {Promise<unknown>}
     */
    getRoomHeat(roomId){
        return this.imSDK.getRoomHeat(roomId)
    }

    /**
     * 获取房间历史记录
     * @param roomId
     * @param start
     * @param end
     * @param page
     * @param size
     * @returns {Promise<unknown>}
     */
    getRoomHistory(roomId, start, end, page, size){
        return this.imSDK.getRoomHistory(roomId, start, end, page, size)
    }

    /**
     * 更新热度
     * @param click
     * @returns {Promise<unknown>}
     */
    updateRoomHeat(roomId, click) {
        return this.imSDK.updateRoomHeat(roomId, click)
    }

    /**
     * 发送文本消息
     * @param data
     * @returns {Promise<unknown>}
     */
    sendTextMessage(roomId, data){
        return this.imSDK.sendTextMessage(roomId, data)
    }

    /**
     * 发送私聊消息
     * @param roomId
     * @param target
     * @param data
     * @returns {Promise<unknown>}
     */
    sendPrivateMsg(roomId, target, data){
        return this.imSDK.sendPrivateMsg(roomId, target, data)
    }

    /**
     * 更新昵称和头像
     * @param data
     * @returns {Promise<unknown>}
     */
    updateNickAndAvatar(data){
        return this.imSDK.updateNickAndAvatar(data)
    }

    /**
     * 用户自己退出房间
     */
    exitRoom(){
        this.imSDK.exitRoom()
    }

    /**
     * 监听事件
     * @param event
     * @param listener
     */
    on(event, listener) {
        this.imSDK.on(event, listener)
    }

    /**
     * 一次性监听事件
     * @param event
     * @param listener
     */
    once(event, listener) {
        this.imSDK.once(event, listener)
    }

    /**
     * 移除事件
     * @param event
     * @param listener
     */
    off(event, listener) {
        this.imSDK.off(event, listener)
    }
}

export default YbIm