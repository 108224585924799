/**
 * 医百 meet SDK
 */
 import YB_RTC from "@ybmeet/yb_rtc_sdk"
 import Logan from "logan-sdk-custom"

import IM_EVENT from "./plugins/IM/lib/api/im-events"
import configs from './plugins/config'
import { EnhancedEventEmitter } from './utils/enhancedEventEmitter';
// import {
//   initLocalDeviceInfo
// } from '../utils/device'

type SDKOptions = {
  appId: string,
  appSecret: string,
  mode: string, // pro , dev , test
  plugins?: Array<string>, // 插件枚举
}

export default class YBMeetSDK extends EnhancedEventEmitter {

  static IM_EVENT = IM_EVENT

  private plugins: object = {};

  constructor(options: SDKOptions) {
    super()
    // initLocalDeviceInfo()
    sessionStorage.setItem('YB_MEET_SDK_ENV', options.mode)
    this.init(options)
  }

  private roomClient: any;
  public init(options) {
    this.roomClient = new YB_RTC()
    this.installPlugins(options)
  }

   /**
   * 写入日志
   */
    public writeLog(msg, type) {
      Logan.log(msg, type)
    }

    /**
     * 上传日志
     * @returns {Promise}
     */
     public async uploadLogfile(options:any = {}) {
      return new Promise(async (resolve, reject) => {
        try {
          const data = await Logan.report(options)
          resolve(data)
        } catch (error) {
          reject(error)
        }
      })
    }

  public installPlugins(options) {
    console.log(configs);
    Object.values(configs)
      .forEach(item => {
        // eslint-ignore
        this[item.key] = new item.ref(this, { ...options })
      })
  }
}
