import debug from 'debug'

export default class Logger
{
	constructor(APP_NAME)
	{
		this._debug = debug(APP_NAME);
		this._warn = debug(`${APP_NAME}:WARN`);
		this._error = debug(`${APP_NAME}:ERROR`);

		/* eslint-disable no-console */
		this._debug.log = console.info.bind(console);
		this._warn.log = console.warn.bind(console);
		this._error.log = console.error.bind(console);
		/* eslint-enable no-console */
	}

	get debug()
	{
		return this._debug;
	}

	get warn()
	{
		return this._warn;
	}

	get error()
	{
		return this._error;
	}
}
