/*
 * Sun-Sky  2022/4/12 下午6:28
 */

import { getUserInfo } from '@/api/user'
import { Commit } from 'vuex'
import { getToken, setToken } from '@/utils/storage'
import router from '@/router/index'

async function SetUserInfo() {
  // 获取用户信息
  let res = await getUserInfo({})
  let { code, data }:any = res
  if (code === 200) {
    // window.location.replace('/certification/identity')
    return data
  }
}

export default {
  state() {
    return {
      userInfo: {}, // 用户信息
    }
  },
  mutations: {
    SET_USER_INFO(state: any, user: any) {
      state.userInfo = user
    },
  },
  getters: {
    userInfo: (state: any) => state.userInfo,
  },
  actions: {
    async setUserInfo({ commit }: { commit: Commit }) {
    //   const haveLogged = !!getToken()
    //   console.log(haveLogged,9999999,getToken())
      /*
       * 缓存用户信息
       * */
    //   if (!haveLogged) return
      const data: any = await SetUserInfo() // 缓存用户信息
      console.log(data,9999999)
      commit('SET_USER_INFO', data)
      const hasIdentity = data?.identitySave === 1
      if (!hasIdentity) {
        router.push({ name: 'identity' })
      }
    },
    cleanUserInfo({ commit }: { commit: Commit }) {
      commit('SET_USER_INFO', {})
    },
  },
}
