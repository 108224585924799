import axios from "./request";
import * as IAPiInterface from '@/typings/api'
import { baseUrl, switcherUrl } from '@/config'

const basePath = '/applet/collect-applet-api'

// 弃 -- 换取通用token
export function getAccessToken(data = {
    grant_type: "client_credentials",
    client_id: "yb_collect_toutiao_applet",
    client_secret: "yb_collect_toutiao_applet_123456"
}) {
    return axios({
        url: 'https://mg.test.100api.cn/ybmid/middle-auth/login/oauth2/token',
        method: 'POST',
        data
    })
}
export const checkSave = (data:any) => {
    return axios({
        method: 'POST',
        url: `${basePath}/project/caseFormData/check/period/submit`,
        data,
    })
};   //校验随访表单是否可以保存
export const checkBlack = (data:any) => {
    return axios({
        method: 'POST',
        url: `${basePath}/project/user/black/check`,
        data,
    })
};   //校验是否黑名单
export const submitSurvey = (data:any) => {
    return axios({
        method: 'POST',
        url: `${basePath}/project/frontSurveyFormData/submitData`,
        data,
    })
};   //调研问卷提交
export const saveSurvey = (data:any) => {
    return axios({
        method: 'POST',
        url: `${basePath}/project/frontSurveyFormData/draftData`,
        data,
    })
};   //调研问卷保存

// ============== 表单 & 病例征集相关API ==============
// 医学研究表单草稿保存
export const caseFormDataDraftSave = (data:any) => {
    return axios({
        method: 'POST',
        url: `${basePath}/project/caseFormData/draft/save`,
        data,
    })
}
export const checkPeriodIscanSubmit = (params:any) => {
    return axios({
        method: 'GET',
        url: `${basePath}/project/casePeriod/checkIscanSubmit`,
        params
    })
};   //校验周期是否可提交
export const checkFormIscanSubmit = (params:any) => {
    return axios({
        method: 'GET',
        url: `${basePath}/project/caseFormData/checkIscanSubmit`,
        params
    })
};   //校验独立表单是否可提交
export const getRepetitionBusinessStatus = () => {
    return axios({
        method: 'GET',
        url: `${basePath}/project/caseFormData/getRepetitionBusinessStatus`,
    })
};   //查询参与查重的业务状态集合
export const rollbackFieldRepetition = (data:any) => {
    return axios({
        method: 'POST',
        url: `${basePath}/project/casePeriod/rollbackFieldRepetition`,
        data,
    })
};   //回退周期下表单字段查重
export const delPictureRepetition = (data:any) => {
    return axios({
        method: 'POST',
        url: `${basePath}/project/caseFormData/delPictureRepetition`,
        data,
    })
};   //图片去重删除情况
export const checkPictureRepetition = (data:any) => {
    return axios({
        method: 'POST',
        url: `${basePath}/project/caseFormData/checkPictureRepetition`,
        data,
    })
};   //图片校验重复度
export const getCasePeriod = (params:any) => {
    return axios({
        method: 'GET',
        url: `${basePath}/project/casePeriod/form/echo`,
        params,
    })
};   //获取周期表单

export const submitUserPeriod = (data:any) => {
    return axios({
        method: 'POST',
        url: `${basePath}/project/casePeriod/submit/user_task_period`,
        data,
    })
};   //提交周期表单
export const middleFormFillInCompensation = (data:any) => {
    return axios({
        method: 'POST',
        url: `${basePath}/project/casePeriod/middleFormFillInCompensation`,
        data,
    })
};
export const caseUserTask = (data:any) => {
    return axios({
        method: 'POST',
        url: `${basePath}/project/caseUserTask/submit`,
        data,
    })
};//独立表单任务提交
// export const submitByMiddleDetailId = (data:any) => {
//     return axios({
//         method: 'POST',
//         url: `${baseUrl}${basePath}/project/caseFormData/submitByMiddleDetailId`,
//         data,
//     })
// };
export const submitByMiddleDetailId = (data:any) => {
    return axios({
        method: 'POST',
        url: `${basePath}/project/caseFormData/submitByMiddleDetailId/compensate`,
        data,
    })
};
export const caseFormDataSubmit = (data:any) => {
    return axios({
        method: 'POST',
        url: `${basePath}/project/caseFormData/submit`,
        data,
    })
};

export const getUserChecknormAnswer = (params: { caseFormId: string, userId: string, bizId: string }) => {
    return axios({
        method: 'GET',
        url: `${basePath}/project/checknormAnswer/getUserChecknormAnswer`,
        params,
    })
};


export const caseFormDataDelete = (params: { id: string }) => {
    return axios({
        method: 'POST',
        url: `${basePath}/project/caseFormData/delete`,
        data: params,
    })
};
export const checkSubmit = (params: { id: string }) => {
    return axios({
        method: 'GET',
        url: `${basePath}/project/caseFormData/checkSubmit`,
        params,
    })
};


// ============== END ==============


// ============== 导播台API ==============

// 创建导播台
export function createSwitcher(data: IAPiInterface.IApiCreateSwitcher) {
    return axios({
        url: `${switcherUrl}/switcher/create`,
        method: 'POST',
        data
    })
}

// 停止导播台
export function stopSwitcher(switcherId: string) {
    return axios({
        url: `${switcherUrl}/switcher/stop/${switcherId}`,
        method: 'GET'
    })
}

// 销毁导播台
export function destroySwitcher(switcherId:string) {
    return axios({
        url: `${switcherUrl}/switcher/destroy/${switcherId}`,
        method: 'DELETE',
    })
}

// 开启导播台
export function startSwitcher(data: IAPiInterface.IApiStartSwitcher) {
    return axios({
        url: `${switcherUrl}/switcher/start`,
        method: 'POST',
        data
    })
}

// 添加rtc/图片输入源
export function switcherAddInputSource(data: IAPiInterface.IApiSwitcherAddInputSource) {
    return axios({
        url: `${switcherUrl}/switcher/input`,
        method: 'POST',
        data
    })
}

// 更新图片输入源
export function switcherUpdateImgSource(data: IAPiInterface.IApiSwitcherUpdateImgSource) {
    return axios({
        url: `${switcherUrl}/switcher/input`,
        method: 'put',
        data
    })
}

// 添加CG字幕
export function switcherAddCGText(data: IAPiInterface.IApiSwitcherAddCGTextSource) {
    return axios({
        url: `${switcherUrl}/switcher/addCgObject`,
        method: 'POST',
        data
    })
}

// 启用CG字幕
export function switcherUseCGText(data: IAPiInterface.IApiSwitcherUseCGTextSource) {
    return axios({
        url: `${switcherUrl}/switcher/startCgObject`,
        method: 'POST',
        data
    })
}
// 停用CG字幕
export function switcherStopCGText(data: IAPiInterface.IApiSwitcherUseCGTextSource) {
    return axios({
        url: `${switcherUrl}/switcher/stopCgObject`,
        method: 'POST',
        data
    })
}

// 设置导播台混流模版 一分屏/二分屏
export function switcherSetMeTemplate(data: IAPiInterface.IApiSwitcherTempleteLayout) {
    return axios({
        url: `${switcherUrl}/switcher/setMeTemplate`,
        method: 'POST',
        data
    })
}

// 更新导播台混流模版 一分屏/二分屏
export function switcherUpdateMeTemplate(data: IAPiInterface.IApiSwitcherTempleteLayout) {
    return axios({
        url: `${switcherUrl}/switcher/updateLayout`,
        method: 'POST',
        data
    })
}

// 设置/更新输出源地址
export function switcherSetRtcOutput(data: IAPiInterface.IApiSwitcherRtcOutput, type = 'add') {
    return axios({
        url: `${switcherUrl}/switcher/output`,
        method: type === 'add' ? 'POST' : 'put',
        data
    })
}

// 删除输出源地址
type DeleteOutputSource = {
    switcherId: string,
    componentId: number
}
export function switcherDeleteRtcOutput(data:DeleteOutputSource) {
    return axios({
        url: `${switcherUrl}/switcher/output/${data.switcherId}/${data.componentId}`,
        method: 'DELETE'
    })
}

// 节点连接
export function switcherConnectNode(data: IAPiInterface.IApiSwitcherConnectNode) {
    return axios({
        url: `${switcherUrl}/switcher/connectNode`,
        method: 'POST',
        data
    })
}

// 取消节点连接
export function switcherDisConnectNode(data: IAPiInterface.IApiSwitcherConnectNode) {
    return axios({
        url: `${switcherUrl}/switcher/disConnectNode`,
        method: 'POST',
        data
    })
}
// ============== END ==============

// ============== SaaS API ==============

// 直播任务Id
type IGetConference = {
    liveVideoId?: string,
    id?: string,
    normalPauseFlag?: boolean
}

// 创建会议获取会议号
export function getConference(data: IGetConference) {
    return axios({
        url: `${baseUrl}${basePath}/project/live-video/conference-no`,
        method: 'GET',
        params: data
    })
}

// 获取导播台推流地址
export function getPushLiveUrl(data: IGetConference) {
    return axios({
        url: `${baseUrl}${basePath}/project/live-stream/get`,
        method: 'GET',
        params: data
    })
}

// 获取直播详情
export function getLiveDetails(data: IGetConference) {
    return axios({
        url: `${baseUrl}${basePath}/project/live-video/detail`,
        method: 'GET',
        params: data
    })
}

// 直播开始前校验是否达到最大任务数
export function liveStart(params:any) {
    return axios({
        url: `${baseUrl}${basePath}/project/live-video/start`,
        method: 'GET',
        params
    })
}

//获取演示文档列表
type IGetDocList = {
    liveVideoId: string,
    pageNum: number,
    pageSize: number
}
export function getDocList(data: IGetDocList) {
    return axios({
        url: `${baseUrl}${basePath}/project/live-video/all-ppt-info`,
        method: 'POST',
        data
    })
}

//获取演示文档详情
type IGetDocDetails = {
    id: string,
    pptId: string
}
export function getDocDetails(data: IGetDocDetails) {
    return axios({
        url: `${baseUrl}${basePath}/project/live-video/select-ppt`,
        method: 'GET',
        params: data
    })
}

//结束当前演示文档
type IDocOverShow = {
    liveVideoId: string,
    pptId: string
}
export function docOverShow(data: IDocOverShow) {
    return axios({
        url: `${baseUrl}${basePath}/project/live-video/over-doc-show`,
        method: 'GET',
        params: data
    })
}

//直播暂停(旁路)
export function CDNLivePause(data: IGetConference) {
    return axios({
        url: `${baseUrl}${basePath}/project/live-stream/pause`,
        method: 'GET',
        params: data
    })
}

//直播恢复(旁路)
export function CDNLiveResume(data: IGetConference) {
    return axios({
        url: `${baseUrl}${basePath}/project/live-stream/resume`,
        method: 'GET',
        params: data
    })
}

//直播结束
export function LiveClose(data: any) {
    return axios({
        url: `${baseUrl}${basePath}/project/live-stream/over`,
        method: 'GET',
        params: data
    })
}
//继续直播
export function LiveContinue(params: any) {
    return axios({
        url: `${baseUrl}${basePath}/project/live-video/goon`,
        method: 'GET',
        params
    })
}
// ppt翻页记录
type ITurnPagesRecord = {
    liveVideoId: string,
    pptId: string,
    currentPageNum: number
};
export function turnPagesRecord(data: ITurnPagesRecord) {
    return axios({
        url: `${baseUrl}${basePath}/project/live-video/record-ppt-page-num`,
        method: 'GET',
        params: data
    })
}

//直播结束
export function getVideoInfo(data: IGetConference) {
    return axios({
        url: `${baseUrl}${basePath}/project/live-stream/over`,
        method: 'GET',
        params: data
    })
}

//直播结束
export function getLiveInfo(data: IGetConference) {
    return axios({
        url: `${baseUrl}${basePath}/project/live-stream/over`,
        method: 'GET',
        params: data
    })
}


// ============== END ==============

