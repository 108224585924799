import { IGlobalState } from '..';
import { IYbMeetInfo } from "@/typings/ybrtc";
import { Module } from "vuex";
import * as Types from '@/store/action-types';

const state:IYbMeetInfo = {
    roomid:"",

    peerId:"", // 会议服务器用户Id -- roomid + '_' + userid

    agendaPermission: 0, // 议议程权限 0：所有人可见 1：仅主持人可见

    allMuteState: 0, // 全局的静音状态  0：全体解除静音  1：全体静音

    allowEarlyEntry: 0, // 是否允许提前进入  1：是；0否；

    allowSelfUnmute: 1, // 允许成员自我解除静音 1是；0：否；
    
    links: "", // 邀请链接

    lockedState: 0, // 锁定状态： 0未锁定；1锁定

    muteJoinMeeting: 0, // 成员加入会议静音 1：是；0否；

    ownerId: "", // 会议所有者id

    ownerName: "", // 会议所有者name

    ownerPasswordEnable: 0, // 是否有主持人密码，0否，1是

    password: "", //会议密码

    passwordEnable: 0, // 是否使用密码，0否，1是

    playTips: 0, // 成员加入会议时播放提示因 1：是；0否；

    recordPermission: 0, // 录制权限  0：所有人可录制  1：仅主持人可录制
    
    sharePermission: 0, // 共享权限  0：所有人可共享  1：仅主持人可共享

    title: "",

    conferenceNo: "", // 会议号

    liveDetails: {}, // 直播详情信息---saas业务接口返回数据
}

/**
 * @Module params 1 自己状态; params 2 全局状态
 */
 const meet: Module<IYbMeetInfo, IGlobalState> = {
    namespaced: true,
    state,
    mutations:{
        [Types.SET_MEET_INFO](state,payload) {
            Object.keys(payload).map(key =>{
                state[key] = payload[key]
            })
            console.log('入会信息==>',payload)
        },

        [Types.SET_LIVE_INFO](state,payload) {
            let cgWrapHospitalText = payload.hospital;
            if(payload.hospital && payload.hospital.length >= 15){
                cgWrapHospitalText = insertStr(payload.hospital, 15, '\n');
                if(payload.hospital && payload.hospital.length >= 31){
                    cgWrapHospitalText = insertStr(cgWrapHospitalText, 31, '\n');
                }
            }

            payload = { cgWrapHospitalText: cgWrapHospitalText, ...payload };

            state.liveDetails = payload
        }
    }
}

/**
 * 指定位置插入字符
 * @param soure 原字符串
 * @param start 位置
 * @param newStr 要插入的字符
 */
const insertStr = function(soure:string, start:number, newStr:string){
    return soure.slice(0, start) + newStr + soure.slice(start);
}

export default meet