import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import FormGenerator from '../views/formGenerator/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'FormGenerator',
    component: FormGenerator
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: () => import('@/views/pdf.vue'),
    meta: { title: '查看文件' },
  },
  {
    path: '/doc',
    name: 'doc',
    component: () => import('@/views/doc.vue'),
    meta: { title: '查看文件' },
  },
  {
    path: '/expert',
    name: 'expert',
    component: () => import('@/views/expert/index.vue'),
    meta: { title: '任务详情' },
  },
  {
    path: '/aiArticle',
    name: 'aiArticle',
    component: () => import('@/views/aiArticle/index.vue'),
    meta: { title: '任务详情' },
  },
  {
    path: '/aiArticleDetail',
    name: 'aiArticleDetail',
    component: () => import('@/views/aiArticle/detail.vue'),
    meta: { title: '任务详情' },
  },
  {
    path: '/aiCaseStory',
    name: 'aiCaseStory',
    component: () => import('@/views/aiCaseStory/index.vue'),
    meta: { title: '任务详情' },
  },
  {
    path: '/aiCaseStoryDetail',
    name: 'aiCaseStoryDetail',
    component: () => import('@/views/aiCaseStory/detail.vue'),
    meta: { title: '任务详情' },
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('@/views/case/index.vue'),
    meta: { title: '填写病例' },
  },
  {
    path: '/caseFollow',
    name: 'caseFollow',
    component: () => import('@/views/case/caseFollow/index.vue'),
    meta: { title: '填写病例' },
  },
  
  {
    path: '/caseDetail',
    name: 'caseDetail',
    component: () => import('@/views/case/detail.vue'),
    meta: { title: '表单详情' },
  },
  {
    path: '/launch-live',
    name: 'LaunchLive',
    component: () => import(/* webpackChunkName: "LaunchLive" */ '../views/launchLive/index.vue')
  },
  {
    path: '/launch-error',
    name: 'LaunchError',
    component: () => import(/* webpackChunkName: "LaunchLive" */ '../views/launchLive/error.vue')
  },
  {
    path: '/detail/video/:type?/:id?/:position?',
    name: '视频详情',
    component: () => import('@/views/seeVideo/video/index.vue'),
  },
  {
    path: '/detail/hdvideo/:type?/:id?/:position?',
    name: '互动直播视频详情',
    component: () => import('@/views/seeVideo/video/iaVideo.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
