import axios from "./request";
import * as IAPiInterface from '@/typings/api'
import { baseUrl, switcherUrl } from '@/config'

const basePath = '/applet/collect-applet-api'
const Gateway = '/collect/collect-video-api'
const userPath = baseUrl+'/collect/collect-user-api'

// ============== 表单 & 病例征集相关API ==============
export const getCasePeriod = (params:any) => {
    return axios({
        method: 'GET',
        url: `${baseUrl}${basePath}/project/casePeriod/form/echo`,
        params,
    })
};   //获取周期表单

export const getRegion = (data:any) => {
    return axios({
        method: 'POST',
        url: `${baseUrl}${basePath}/project/casePeriod/submit/user_task_period`,
        data,
    })
};  
 // 获取用户信息
export const getUserInfo = (data:any) => {
    return axios({
        method: 'POST',
        url: `${userPath}/user/info`,
        data,
    })
};  




