import Cookies from 'js-cookie'
import { LANG_ZH, LANG_EN, LANG_AUTO } from '../constant/index'

const TokenKey = 'ybToken'
const refreshTokenKey  = 'ybRefreshToken'
const clientIdKey = 'cId'
const clientSecretKey = 'cs'
const acceptLanguageKey = 'acceptLanguage'

const avatarKey = 'avatar'
const uuidKey = 'uuid'
const meetingRoleKey = 'ybMeetingRole'

const mobileKey = 'loginMobile'
const phoneKey = 'loginPhone'
const emailKey = 'loginEmail'
const localConferenceNoKey = 'localConferenceNoKey'
const displayNameKey = 'displayNameKey'

const conferenceTokenKey = 'X-Conference-Token'
const channelTokenKey = 'X-Channel-Token'

const conferenceNoKey = 'conferenceNo'


// 获取会议服务token
export function getConferenceToken () {
    return localStorage.getItem(conferenceTokenKey)
}
// 设置会议服务token
export function setConferenceToken (token) {
    localStorage.setItem(conferenceTokenKey, token)
}
// 移除会议服务token
export function removeConferenceToken () {
    localStorage.removeItem(conferenceTokenKey)
}


// 获取IM token
export function getChannelToken () {
    return localStorage.getItem(channelTokenKey)
}
// 设置IM token
export function setChannelToken (token) {
    localStorage.setItem(channelTokenKey, token)
}
// 移除IM token
export function removeChannelToken () {
    localStorage.removeItem(channelTokenKey)
}
// 获取会议号
export function getConferenceNo () {
    return Cookies.get(conferenceNoKey)
} 


/**
 * 存储会议相关信息
 * @param {*} meetToken 
 * @param {*} imToken 
 */
export function setMeetInfo(meetToken, imToken) {
    setConferenceToken(meetToken)
    setChannelToken(imToken)
}

/**
 * 清空会议相关信息
 */
export function removeMeetInfo() {
    removeConferenceToken()
    removeChannelToken()
}




// 获取登录token
export function getToken() {
    return Cookies.get(TokenKey)
}
// 登录token赋值
export function setToken(token) {
    Cookies.set(TokenKey, token)
}
// 移除登录token
export function removeToken() {
    Cookies.remove(TokenKey)
}


// refreshToken
export function getRefreshToken() {
    return Cookies.get(refreshTokenKey)
}

export function setRefreshToken(token) {
    Cookies.set(refreshTokenKey, token)
}

export function removeRefreshToken() {
    Cookies.remove(refreshTokenKey)
}

// 获取语言值
export function getAcceptLanguage() {
    if(window.__wxjs_environment === 'miniprogram') return LANG_ZH;

    const localLang = getLocalLanguage()
    const sysLang = navigator.language === LANG_ZH ? LANG_ZH: LANG_EN

    if (!localLang) { // 不存在，存储自动选项
        setLocalLanguage(LANG_AUTO)
        return sysLang
    } else { // 存在
        if (localLang === LANG_AUTO) { // 自动
            return sysLang
        } else {
            return localLang
        }
    }

}
export function isZhLang() {
   return getAcceptLanguage() === LANG_ZH
}
export function getLocalLanguage() {
   return localStorage.getItem(acceptLanguageKey)
}
export function setLocalLanguage(val) {
   localStorage.setItem(acceptLanguageKey, val)
}
export function removeLocalLanguage() {
   localStorage.removeItem(acceptLanguageKey)
}

// client_id
export function getClientId() {
    return Cookies.get(clientIdKey)
}

export function setClientId(val) {
    Cookies.set(clientIdKey, val)
}

export function removeClientId() {
    Cookies.remove(clientIdKey)
}

// client_secret
export function getClientSecret() {
    return Cookies.get(clientSecretKey)
}

export function setClientSecret(val) {
    Cookies.set(clientSecretKey, val)
}

export function removeClientSecret() {
    Cookies.remove(clientSecretKey)
}



// 头像
export function getAvatar () {
    return Cookies.get(avatarKey)
}
export function setAvatar (avatar) {
    return Cookies.set(avatarKey, avatar)
}
export function removeAvatar () {
    return Cookies.remove(avatarKey)
}


// 设置uuid
export function setUuid (token) {
    return Cookies.set(uuidKey, token)
}
export function getUuid () {
    return Cookies.get(uuidKey)
}
// 移除uuid
export function removeUuid () {
    return Cookies.remove(uuidKey)
}

/**
 * 设置用户会议角色(加入时 设置)
 * 20 =>主持人 
 * 10 =>联席主持人
 */
export function setMeetingRole (value) {
    return Cookies.set(meetingRoleKey, value)
}
export function getMeetingRole () {
    return Cookies.get(meetingRoleKey)
}
// 移除meetingRole
export function removeMeetingRole () {
    return Cookies.remove(meetingRoleKey)
}


// reset
export function removeUserInfo() {
    removeToken()
    removeRefreshToken()
    removeAvatar()
    removeMeetingRole()
    
    if(window.__wxjs_environment !== 'miniprogram') {
      removeClientId()
      removeClientSecret()
    }
}