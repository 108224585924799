import Base from "../BasePlugin"
import {
    fetchUserInfo,
    updateUserInfo,
    modifyPersonalPassword,
    modifyEnterprisePassword,
    updatePersonConference
} from '../../api/meet'

import {
    setToken,
    getToken,
    removeUserInfo
} from '../../utils/auth'
import { SDK_ERROR, LOGIN_TYPE } from '../../constant'
import { ResultError, ResultEntity } from '../../utils/entity'
import { checkEmail, checkMobile, checkSmallPwd } from '../../utils/check'

import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import CryptoJS from "crypto-js";

/**
 * 服务模块（用户认证、会议管理）
 */
class MeetSetting extends Base {
    constructor(app, config) {
        super(app);
    }
    /**
     * 2.退出登录
     * @returns {Promise}
    */
    public logout() {
        removeUserInfo()
        return Promise.resolve()
    }




    /**
     * 5.获取用户信息
     * @returns {Promise}
    */
    public getUserInfo() {
        return new Promise(async (resolve, reject) => {
            if (!getToken()) {
                const error = new ResultError(
                    'NOT_LOGIN',
                    SDK_ERROR.NOT_LOGIN,
                    'getUserInfo: no login')
                reject(error)
                return
            }

            try {
                const result = await fetchUserInfo()
                if (result)
                    resolve(result)

            } catch (error) {
                reject(error)
            }
        })
    }

    /**
     * 6.修改用户名
     * @param {*} userName 用户名
     * @returns {Promise}
    */
    public changeUserName(userName) {
        return new Promise<void>(async (resolve, reject) => {
            if (!getToken()) {
                const error = new ResultError(
                    'NOT_LOGIN',
                    SDK_ERROR.NOT_LOGIN,
                    'changeUserName: no login')
                reject(error)
                return
            }

            try {
                await updateUserInfo({ nickname: userName })
                resolve()

            } catch (error) {
                reject(error)
            }
        })
    }

    /**
     * 7.修改用户密码
     * @param {*} userName 用户名
     * @param {*} oldPassword 旧密码
     * @param {*} newPassword 新密码
     * @returns {Promise}
     */
    public changeUserPassword(userName, oldPassword, newPassword, enterpriseId) {
        return new Promise (async (resolve, reject) => {
            if (!userName) {
                const error = new ResultError(
                    'PARAMETERS',
                    SDK_ERROR.PARAMETERS,
                    'changeUserPassword: parameter error')
                reject(error)
                return
            }

            if (oldPassword === newPassword) {
                console.log("原密码和新密码一致")
                const error = new ResultError(
                    'OLDPASSWORD_EQUAL_NEWPASSWORD',
                    SDK_ERROR.OLDPASSWORD_EQUAL_NEWPASSWORD,
                    'changeUserPassword: old password is different from the new password')
                reject(error)
                return
            }

            if (!oldPassword || !newPassword) {
                console.log("原密码或新密码为空")
                const error = new ResultError(
                    'OLDPASSWORD_OR_NEWPASSWORD_EMAPTY',
                    SDK_ERROR.OLDPASSWORD_OR_NEWPASSWORD_EMAPTY,
                    'changeUserPassword: old password or new password is empty')
                reject(error)
                return
            }

            if (enterpriseId && enterpriseId !== '0' && !checkEmail(userName)) {
                const error = new ResultError(
                    'EMAIL_FORMAT',
                    SDK_ERROR.EMAIL_FORMAT,
                    `changeUserPassword: email(${userName}) format error`)
                reject(error)
                return
            }

            const params: any = {}
            params.password = Base64.stringify(
                CryptoJS.enc.Utf8.parse(sha256(oldPassword).toString())
            )
            params.newPassword = Base64.stringify(
                CryptoJS.enc.Utf8.parse(sha256(newPassword).toString())
            )
            params.username = userName

            try {
              if (enterpriseId && enterpriseId !== '0') {
                    params.enterpriseId = enterpriseId
                    await modifyEnterprisePassword(params)
                } else {
                    await modifyPersonalPassword(params)
                }
                resolve('')

            } catch (error) {
                reject(error)
            }
        })
    }

    /**
     * 8.修改个人会议号信息
     * @param {*} entity 个人会议号信息实体
     * @returns {Promise}
     */
    public changePersonalMeetingInfo(conferenceNo, entity) {
        return new Promise(async (resolve, reject) => {
            if (!entity || !conferenceNo) {
                const error = new ResultError(
                    'PARAMETERS',
                    SDK_ERROR.PARAMETERS,
                    'changePersonalMeetingInfo: parameter error')
                reject(error)
                return
            }

            if ((entity.passwordEnable && !checkSmallPwd(entity.password))
                || (entity.ownerPasswordEnable && !checkSmallPwd(entity.ownerPassword))) {
                const error = new ResultError(
                    'PASSWORD_FORMAT',
                    SDK_ERROR.PASSWORD_FORMAT,
                    'changePersonalMeetingInfo: password format error')
                reject(error)
                return
            }

            try {
                const result = await updatePersonConference(conferenceNo, entity)
                if (result)
                    resolve(result)
            } catch (error) {
                reject(error)
            }

        })
    }
}

export default MeetSetting;
