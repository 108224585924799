import {Commit} from 'vuex'

export default {
    state() {
        return {
            emojiMap: null, // 表情包
            currentMessageList: [], // 聊天内容
        }
    },
    mutations: {
        SET_PASSWORD(state: any, data: any) {
            state.watchPassword = data
        },
        SET_CURRENT_MESSAGE_LIST(state: any, data: any) {
            state.currentMessageList = data
        },
        RESET_CURRENT_MESSAGE_LIST(state: any, data: any) {
            state.currentMessageList = data
        },
        SET_EMOJI_MAP(state: any, data: any) {
            state.emojiMap = data
        }
    },
    getters: {
        emojiMap: (state: any) => state.emojiMap,
        currentMessageList: (state: any) => state.currentMessageList,
    },
    actions: {
        setWatchPassWord({commit}: { commit: Commit },data: string) {
            commit('SET_PASSWORD', data)
        },
        setCurrentMessageList({commit}: { commit: Commit },data: any) {
            commit('SET_CURRENT_MESSAGE_LIST', data)
        },
        resetCurrentMessageList({commit}: { commit: Commit }) {
            commit('RESET_CURRENT_MESSAGE_LIST', [])
        },
        setEmojiMap({commit}: { commit: Commit },data: Object) {
            commit('SET_EMOJI_MAP', data)
        }
    }
}
