
import Base from "../BasePlugin"
import { SDK_ERROR, LOGIN_TYPE } from '../../constant'
import { ResultError, ResultEntity } from '../../utils/entity'
import { checkEmail, checkMobile, checkSmallPwd } from '../../utils/check'
import {
  exitEnterprise,
  exitPermituser,
  oauthLogin,
  sendCode,
  modifyPersonalPassword,
  modifyEnterprisePassword,
 } from '../../api/meet'
 import {
  setToken,
  setRefreshToken,
  setClientId, 
  setClientSecret,
  removeUserInfo
} from "../../utils/auth"
 import sha256 from "crypto-js/sha256";
 import Base64 from "crypto-js/enc-base64";
 import CryptoJS from "crypto-js";

class Auth extends Base {
    constructor(app, config) {
        super(app);
    }
    /**
   * 1.密码登录
   * @param {*} phoneNoOrEmail
   * @param {*} password
   * @param {*} loginType 登录类型，只能指定： LOGINTYPE_PERSONAL_PASSWORD, LOGINTYPE_ENTERPRISE
   */
     loginWithPassword(phoneNoOrEmail, password, loginType) {

        return new Promise(async (resolve, reject) => {
            if (!phoneNoOrEmail || !password || (loginType !== LOGIN_TYPE.ENTERPRISE
                && loginType !== LOGIN_TYPE.PERSONAL_PASSWORD))  {
                const error = new ResultError(
                    'PARAMETERS',
                     SDK_ERROR.PARAMETERS,
                    '参数错误')
                reject(error)
                return
            }

            //验证格式
            if (loginType == LOGIN_TYPE.ENTERPRISE) {

              if (!checkEmail(phoneNoOrEmail)) {
                const errorMsg = `邮箱(${phoneNoOrEmail}) 格式错误`
                reject(new ResultError('format error', SDK_ERROR.EMAIL_FORMAT, errorMsg))
                return
              }
            } else {
              if (!checkMobile(phoneNoOrEmail))
              {
                const errorMsg = `手机号码(${phoneNoOrEmail}) 格式错误`
                reject(new ResultError('PHONE_FORMAT', SDK_ERROR.PHONE_FORMAT, errorMsg))
                return
              }
            }

            try {
                //oauth登录实现
                const result = await this.oauthLoginService({
                  userName: phoneNoOrEmail,
                  password,
                  loginType
                })
                if (result.code === SDK_ERROR.SUCCESS && result.data) {
                  resolve(result.data)
                } else {
                  reject(new ResultError(result.name, result.code, result.msg))
                }
            } catch (error) {
              reject(error)
            }

          })
        //   public
        // console.log('say Hello');
    }
     /**
   * 3.获取验证码
   * note: 会对手机号进行有效性验证
   * @param {phoneNo} 手机号
   * @returns {Promise}
   */
      getVerifyCode(phoneNo) {
      return new Promise(async (resolve, reject) => {
        if (!phoneNo) {
          const error = new ResultError(
            'PARAMETERS',
             SDK_ERROR.PARAMETERS,
            '参数错误')
          reject(error)
          return
        }

        if (!checkMobile(phoneNo)) {
          const error = new ResultError(
            'PHONE_FORMAT',
             SDK_ERROR.PHONE_FORMAT,
            `手机号码(${phoneNo}) 格式错误`)
          reject(error)
          return
        }
      // 是否存在个人账户与移动端和web端保持一致，暂不用
        // const isExist = await exitPermituser({
        //   userName: phoneNo
        // })
        // if (!isExist) {
        //   const error = new ResultError(
        //     'PHONE_NOT_REGISTER',
        //      SDK_ERROR.PHONE_NOT_REGISTER,
        //     `getVerifyCode: ${phoneNo} no register`)
        //   reject(error)
        //   return
        // }
        try {
          const result = await sendCode({
             phone: phoneNo
           })
           resolve(result)

        } catch (error) {
          reject(error)
        }
      })
    }
    // 忘记密码

    /**
    * 2.退出登录
    * @returns {Promise}
   */
     logout() {
     removeUserInfo()
     return Promise.resolve()
   }
   /**
   * * 4.通过验证码登录
   * @param {*} phoneNo 手机号
   * @param {*} verifyCode 验证码
   * @returns {Promise}
   */
   loginWithVerifyCode(phoneNo, verifyCode) {
    return new Promise(async (resolve, reject) => {
      if (!phoneNo || !verifyCode) {
        const error = new ResultError(
          'PARAMETERS',
           SDK_ERROR.PARAMETERS,
          '参数错误')
        reject(error)
        return
      }

      try {
        //oauth登录实现
        const result = await this.oauthLoginService({
          userName: phoneNo,
          code: verifyCode,
          loginType: LOGIN_TYPE.PERSONAL_VERIFYCODE
        })
        if (result.code === SDK_ERROR.SUCCESS && result.data) {
          resolve(result.data)
        } else {
          reject(new ResultError(result.name, result.code, result.msg))
        }
      } catch (error) {
        reject(error)
      }

    })
  }

  /**
   * 5.获取用户信息
   * @returns {Promise}
  */
   getUserInfo() {
    return new Promise(async (resolve, reject) => {
      if(!getToken()) {
        const error = new ResultError(
          'NOT_LOGIN',
           SDK_ERROR.NOT_LOGIN,
          'getUserInfo: no login')
        reject(error)
        return
     }

     try {
      const result = await fetchUserInfo()
      if(result)
          resolve(result)

     } catch (error) {
       reject(error)
     }
    })
  }

  /**
   * 6.修改用户名
   * @param {*} userName 用户名
   * @returns {Promise}
  */
  changeUserName(userName) {
    return new Promise(async (resolve, reject) => {
      if(!getToken()) {
        const error = new ResultError(
            'NOT_LOGIN',
             SDK_ERROR.NOT_LOGIN,
            'changeUserName: no login')
        reject(error)
        return
      }

      try {
        await updateUserInfo({ nickname: userName })
        resolve()

      } catch (error) {
        reject(error)
      }
    })
  }

   /**
   * 7.修改用户密码
   * @param {*} userName 用户名
   * @param {*} oldPassword 旧密码
   * @param {*} newPassword 新密码
   * @returns {Promise}
   */
   changeUserPassword(userName, oldPassword, newPassword, enterpriseId) {
    return new Promise(async (resolve, reject) => {
      if (!userName) {
        const error = new ResultError(
          'PARAMETERS',
           SDK_ERROR.PARAMETERS,
          '参数错误')
        reject(error)
        return
      }

      if (oldPassword === newPassword) {
        console.log("原密码和新密码一致")
        const error = new ResultError(
          'OLDPASSWORD_EQUAL_NEWPASSWORD',
           SDK_ERROR.OLDPASSWORD_EQUAL_NEWPASSWORD,
          '原密码和新密码一致')
        reject(error)
        return
      }

      if(!oldPassword || !newPassword){
        console.log("原密码或新密码为空")
        const error = new ResultError(
          'OLDPASSWORD_OR_NEWPASSWORD_EMAPTY',
           SDK_ERROR.OLDPASSWORD_OR_NEWPASSWORD_EMAPTY,
          '原密码或新密码为空')
        reject(error)
        return
      }

      if (enterpriseId && !checkEmail(userName)) {
        const error = new ResultError(
          'EMAIL_FORMAT',
           SDK_ERROR.EMAIL_FORMAT,
          `邮箱(${userName}) 格式错误`)
        reject(error)
        return
      }

      const params = {}
      params.password = Base64.stringify(
          CryptoJS.enc.Utf8.parse(sha256(oldPassword).toString())
      )
      params.newPassword = Base64.stringify(
          CryptoJS.enc.Utf8.parse(sha256(newPassword).toString())
      )
      params.username = userName

      try {
        if (enterpriseId) {
          params.enterpriseId = enterpriseId
          await modifyEnterprisePassword(params)
        } else {
          await modifyPersonalPassword(params)
        }
        resolve()

      } catch (error) {
         reject(error)
      }
    })
  }


    /**
  * 公共函数 登录认证 oauth
  */
  async oauthLoginService({ userName, password, code, loginType }) {

    const data = {}
    if (loginType === LOGIN_TYPE.ENTERPRISE) {
        const encryPassword = Base64.stringify(
          CryptoJS.enc.Utf8.parse(sha256(password).toString())
        )
        data.password = encryPassword
        data.username = userName
        data.grant_type = "mix"
        data.client_id = "yb_meeting_client"
        data.client_secret = "123456"

    } else if (loginType === LOGIN_TYPE.PERSONAL_VERIFYCODE) {
        data.username = userName
        data.grant_type = "sms"
        data.client_id = "yb_meeting_client"
        data.client_secret = "123456"
        data.checkCode = code

    } else if (loginType === LOGIN_TYPE.PERSONAL_PASSWORD) {
        const encryPassword = Base64.stringify(
          CryptoJS.enc.Utf8.parse(sha256(password).toString())
        )
        data.password = encryPassword
        data.username = userName
        data.grant_type = "password"
        data.client_id = "yb_meeting_client"
        data.client_secret = "123456"
    }

    try {

        //验证账号唯一性（业务）
        if (loginType == LOGIN_TYPE.FENTERPRISE) { // 校验邮箱是否注册
            const isExist = await exitEnterprise({
                userName
            })
            if (!isExist) 
              return new ResultEntity(SDK_ERROR.EMAIL_NOT_REGISTER, 'EMAIL_NOT_REGISTER', `:邮箱${userName} 未注册`)
        } else if(loginType == LOGIN_TYPE.PERSONAL_PASSWORD) { // 手机号密码登录
            const isExist = await exitPermituser({
                userName
            })
            if (!isExist) 
              return new ResultEntity(SDK_ERROR.PHONE_NOT_REGISTER, 'PHONE_NOT_REGISTER', `手机号${userName}未注册`)
        }

        const { accessToken, refreshToken } = await oauthLogin(data)

        if (accessToken && refreshToken) {
            setToken(accessToken)
            setRefreshToken(refreshToken)
            setClientId(data.client_id)
            setClientSecret(data.client_secret)

            return new ResultEntity(SDK_ERROR.SUCCESS, '', '', {
                accessToken,
                refreshToken
            })
        }
        return new ResultEntity(SDK_ERROR.LOGIN_FAILED, 'LOGIN_FAILED', '登录失败') 
    } catch (error) {
        throw new Error(error.msg || error.message)
    }
  }
}

export default Auth
