import { IYbUserInfo } from '@/typings/ybrtc';
import { Module } from "vuex";
import { IGlobalState } from '..';
import * as Types from '@/store/action-types'

const state: IYbUserInfo = {
    userid: '',
    userName: '',
    ownerName: '',
    roleName: '',
    roleCode: '',
    avatarUrl: '',
}

const user: Module<IYbUserInfo, IGlobalState> = {
    namespaced: true,
    state,
    mutations: {
        [Types.SET_USER_INFO](state, payload) {
            Object.keys(payload).map(key => {
                state[key] = payload[key]
            })

            console.log('用户信息===>', state)
        }
    }
}

export default user