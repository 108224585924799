import { ILiveState, ISwitcherBypass } from '@/typings/live'
import { IYbMeetInfo, IYbUserInfo } from '@/typings/ybrtc';
import { createStore } from 'vuex'
import live from './modules/meet-command'
import meet from './modules/meet-info'
import user from './modules/user-info'
import company from './modules/company'
import users from './modules/user'
import switcher from './modules/switcher-bypass'
import im from './modules/im'

// 多个store模块在此接口中定义
export interface IGlobalState {
  live: ILiveState,
  meet: IYbMeetInfo,
  user: IYbUserInfo,
  switcher: ISwitcherBypass,
}

const store = createStore<IGlobalState>({
  modules: {
    live,
    meet,
    user,
    users,
    company,
    im,
    switcher
  }
})

export default store
