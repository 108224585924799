

import Auth from './Authentication'
import MeetSetting from './MeetSetting'
import MeetingManagement from './MeetingManagement'
import RTC from './RTC';
import IM from './IM';
import Settings from './RTC/settings';

const ConfigPlugins = {
    auth: {
        key: 'auth',
        ref: Auth
    },
    meetSetting: {
        key: 'meetSetting',
        ref: MeetSetting
    },
    meetingManagement: {
        key: 'meetingManagement',
        ref: MeetingManagement
    },
    rtc: {
        key: 'rtc',
        ref: RTC
    },
    im: {
        key: 'im',
        ref: IM
    },
    settings: {
        key: 'settings',
        ref: Settings
    }
}
export default ConfigPlugins;
