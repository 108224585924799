
import { defineComponent, onMounted, reactive, ref, nextTick } from "vue";
import { isMobile } from "@/utils/util";
import { getUrlParms } from "@/utils/tools";
import { debounce } from "lodash";
import { Dialog, Toast } from "vant";
import { submitSurvey, saveSurvey, checkBlack } from "@/api/index";
import { setCurrencyToken } from "@/utils/auth";

export default defineComponent({
  setup() {
    const formGeneratorMobile = ref(); // 通过 ref 绑定子组件
    const disabled = ref(false);
    const isJoin = ref(false) // 是否是已参与页面，已参与页面无需展示保存按钮
    const submitStatus = ref(null) // 问卷的状态： DRAFT 保存 SUBMITTED 提交
    const projectState = ref(null) // 项目状态： 项目已结束，表单状态为DRAFT也无需展示提交按钮。
    const data = reactive({
      params: JSON.parse(
        decodeURIComponent(decodeURIComponent(getUrlParms("postData") || ""))
      ),
      other: decodeURIComponent(
        decodeURIComponent(getUrlParms("postData") || "")
      ),
    });
    projectState.value = data.params && data.params?.projectState;
    let middleDetailId: any = ref(null);
    let config = reactive({
      isMobile: isMobile(),
    });
    function handleBack() {
      window.parent.postMessage("back", "*");
    }

    const validateMethod = (info: any) => {
      Dialog({
        title: "提示",
        closeOnClickOverlay: true,
        showConfirmButton: false,
        message: info.message,
      });
      Toast.clear();
    };
    const submitForm = debounce(function () {
      checkBlack({ projectId: data.params.projectId }).then((r: any) => {
        if (r.code == 200) {
          Toast.loading({
            duration: 0,
            forbidClick: true,
            message: "正在提交,请稍等...",
            overlay: true,
          });
          //暂不可设置禁用，因为表单sdk未提供 提交时必填项校验失败的钩子，会导致必填项校验报错后却禁用了提交按钮导致无法再次提交的问题
          // disabled.value = true;
          formGeneratorMobile.value?.submitForm();
        } else {
          Toast(r?.msg);
        }
      });
    }, 600);
    function submitSuccessFn(middleDetailId: any) {
      console.log("middleDetailId", middleDetailId);
      let datas = {
        projectId: data.params.projectId,
        userId: data.params.userId,
        companyId: data.params.companyId,
        surveyFormId: data.params.surveyFormId,
        formId: data.params.formId,
        middleDetailId,
      };
      submitSurvey(datas)
        .then((r) => {
          console.log("调研问卷提交", r);
          Toast.clear();
          if (config.isMobile) {
            WeiXin.miniProgram.navigateBack({
              url: `/pages/wenjuanList/index?projectId=${data.params.projectId}&companyId=${data.params.companyId}&userId=${data.params.userId}`,
            });
          } else {
            window.parent.postMessage("back", "*");
          }
        })
        .catch((err: any) => {
          Toast.clear();
        });
    }
    // 保存
    const saveForm = debounce(function (type) {
      console.log(type, 1111);
      checkBlack({ projectId: data.params?.projectId }).then((r: any) => {
        if (r?.code == 200) {
          // if (type == 'btn') {
          // }
          Toast.loading({
            duration: 1000,
            forbidClick: true,
            message: "保存中...",
          });
          // disabled.value = true;
          const el = formGeneratorMobile.value;
          const delay = el ? 0 : 1200;
          setTimeout(() => {
            nextTick(() => {
              console.log("el", el, delay);
              el?.saveForm();
            });
          }, delay);
        } else {
          Toast(r?.msg);
        }
      });
    }, 1500);
    // 保存回调
    function saveSuccessFn(fillId: any) {
      console.log("middleDetailId", fillId);
      middleDetailId.value = fillId;
      let datas = {
        projectId: data.params.projectId,
        userId: data.params.userId,
        companyId: data.params.companyId,
        surveyFormId: data.params.surveyFormId,
        formId: data.params.formId,
        middleDetailId: fillId
      };
      saveSurvey(datas)
        .then((r) => {
          console.log("调研问卷保存", r);
          // Toast.clear();
          // if (config.isMobile) {
          //   WeiXin.miniProgram.navigateBack({
          //     url: `/pages/wenjuanList/index?projectId=${data.params.projectId}&companyId=${data.params.companyId}&userId=${data.params.userId}`,
          //   });
          // } else {
          //   window.parent.postMessage("back", "*");
          // }
        })
        .catch((err: any) => {
          Toast.clear();
        });
    }
    function titleChangeHandle () {
    if (data.params.taskName) {
        document.title = data.params.taskName
    } else {
        document.title = '表单详情'
    }
}
    onMounted(() => {
      titleChangeHandle()
      // document.title = "填写问卷";
      // if (data.params && data.params.fillId) {
      //   document.title = "表单详情";
      // }
      if (data.params && data.params.token) {
        setCurrencyToken(data.params.token);
      }
      console.log("调研问卷57", data.other);
      middleDetailId.value = data.params && data.params.fillId;
      isJoin.value = data.params && data.params.from == 'isJoin';
      submitStatus.value = data.params && data.params?.submitStatus;
    });
    return {
      middleDetailId,
      config,
      handleBack,
      formGeneratorMobile,
      submitSuccessFn,
      saveSuccessFn,
      data,
      submitForm,
      saveForm,
      isJoin,
      submitStatus,
      projectState,
      disabled,
      validateMethod,
    };
  },
});
