export const draggable = {
    mounted(el: any) {
        // 设置目标元素基础属性
        el.style.cursor = 'move';
        el.style.position = 'fixed';
        // 监听鼠标在目标元素上按下        
        el.addEventListener('touchstart', (e: any) => {
       
            const touch = e.touches[0]
            let { width, height } = el.getBoundingClientRect(el);
            let {innerWidth,innerHeight}  = window;
            // 当前目标元素的left与top
            let left = el.offsetLeft;
            let top = el.offsetTop;
            // 保存按下的鼠标的X与Y
            const mouseX = touch.clientX;
            const mouseY = touch.clientY;

            // 监听鼠标移动
            document.ontouchmove = (e) => {
                const touch = e.touches[0]
                // 鼠标移动的距离
                let disX = touch.clientX - mouseX;
                let disY = touch.clientY - mouseY;
                let leftX = left + disX;
                let topY = top + disY;
                //边界判断
                if (leftX < 0) {
                    leftX = 0;
                }
                if (topY < 0) {
                    topY = 0;
                }
                if (leftX>innerWidth-width) {
                    leftX = innerWidth-width;
                }
                if (topY>innerHeight-height) {
                    topY = innerHeight-height;
                }

                el.style.left =leftX+ 'px';
                el.style.top = topY + 'px';
                return false // 防止选中文本，文本拖动的问题
            }

            // 监听鼠标抬起
            document.ontouchend = () => {
                document.ontouchmove = null;
                document.ontouchstart = null;
            }
        });
    }
}


