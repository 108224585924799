
/**
 * 插件基类 - 用于拆分插件使用
 */
 export default class Base {
    public app;
    constructor(app) {

      this.app = app;
      // app 入口类的 执行上下文

      // 委托访问父类 - 原型方法
      this.initDelegate(this.app);
      // 入口类 -方法 到 子类的映射
    }

    initDelegate(app) {
      let appProto = Object.getPrototypeOf(app);

      Object.keys(appProto).forEach(key => {
        this[key] = app[key].bind(app);
        // hack method
      });
    }

    destory() {
      throw 'destory must be implemented';
    }
}
