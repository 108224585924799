
export interface ILiveState {
    liveStatus: LIVE_STATUS, //直播状态
    docStatus: DOC_STATUS, //文档演示状态
    docDemonstrateList: string[], // 文档演示列表数据
    currentDemonDoc: string, // 当前演示的文档地址
    layoutTemplate: LAYOUT_TEMPLATE,
    mediaDeviceStatus: IDeviceStatus, // 媒体设备开启/关闭状态
    mediaDeviceAuthorizeStatus: IDeviceStatus, // 媒体设备授权状态
    barrageChatVisible:boolean, // 聊天弹幕显隐状态
    alreadyAudioAisle: boolean, // 是否已经建立音频通道
    pushStreamUrl: string, //导播台（混流后）推流地址
    liveVideoId: string, // 直播任务Id
    liveProjectId: string, // 直播项目Id
    liveUserId: string, // 直播用户Id
    commandBarVisiable:boolean,// 底部工具栏显示隐藏状态
    disabledInitLock:boolean, // 直播状态初始化操作禁用锁
    currentDemoDocId:'', // 当前所演示的文档任务id
    isActivePuase: boolean, //是否为主动暂停直播
    volumeSize: number,//麦克风音量大小
    disTouchmove:boolean,//是否禁止move事件
}

export enum LIVE_STATUS { //直播状态
    WAIT_LIVING,
    LIVING,
    PAUSE,
    RECOVER,
    END
}

export enum SAAS_LIVE_STATUS { //saas业务直播状态。 0 直播预告，1 正在直播，2 直播暂停，3 直播结束，4直播回放,5直播失效
    PREVIEW,
    LIVING,
    PAUSE,
    END,
    PLAYBACK,
    INVALID
}

export enum DOC_STATUS { // 文档状态
    LECTURING_WAIT,
    LECTURING_END,
    LECTURING
}

// export interface IDocDemonstrateList { // 文档列表
//     id: number,
//     url: string
// }

export enum LAYOUT_TEMPLATE { //布局模版
    BASE, // 基础布局 （二分屏）
    AUDIOVISUAL // 纯音视频 （单屏）
}

// 媒体设备状态 打开/关闭 或 授权/未授权
export interface IDeviceStatus {
    microphone: boolean,
    camera: boolean
}

// 流轨道参数。Mdn MediaStreamTrack属性解释 https://developer.mozilla.org/zh-CN/docs/Web/API/MediaStreamTrack
export interface IStreamTrack {
    contentHint: string
    enabled: boolean
    id: string
    kind: string
    label: string
    muted: boolean
    onended: null
    onmute: null
    onunmute: null
    readyState: string
}

// 导播台混流相关参数
export interface ISwitcherBypass {
    switcherId: string,
    RTCScourceId: number,
    officeImgScourceId: number, //PPT图片源组件ID
    textBgScourceId: number, //字幕背景图片源组件ID
    outputScourceId: number,
    templateId: number,
    cgWordScourceId: number, //CG字幕源组件ID
    cgObjectId: number// CG字幕实例Id
}