import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55a33652"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "form-content"
}
const _hoisted_2 = {
  key: 1,
  class: "formWrap"
}
const _hoisted_3 = {
  key: 0,
  class: "form-butBox"
}
const _hoisted_4 = {
  key: 0,
  class: "case-butBox"
}
const _hoisted_5 = {
  key: 1,
  class: "case-butBox"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_generator_mobile = _resolveComponent("form-generator-mobile")!
  const _component_form_generator_analysis = _resolveComponent("form-generator-analysis")!
  const _component_form_generator_pc = _resolveComponent("form-generator-pc")!

  return (_ctx.projectState !== null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.config.isMobile)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              ((_ctx.submitStatus != 'SUBMITTED' || !_ctx.submitStatus) && _ctx.projectState != 2)
                ? (_openBlock(), _createBlock(_component_form_generator_mobile, {
                    key: 0,
                    ref: "formGeneratorMobile",
                    "form-id": (_ctx.data.params && _ctx.data.params.formId) || '959854787992489984',
                    "user-id": (_ctx.data.params && _ctx.data.params.userId) || '1329655322796658689',
                    "fill-id": (_ctx.data.params && _ctx.data.params.fillId) || _ctx.middleDetailId,
                    "theme-color": "#1AB370",
                    "is-show-submit-btn": false,
                    "is-show-save-btn": false,
                    other: _ctx.data.other,
                    "submit-success-method": _ctx.submitSuccessFn,
                    "save-success-method": _ctx.saveSuccessFn,
                    "validate-method": _ctx.validateMethod,
                    "is-show-success-tips": false,
                    "show-preview-src-list-all": true
                  }, null, 8, ["form-id", "user-id", "fill-id", "other", "submit-success-method", "save-success-method", "validate-method"]))
                : (_openBlock(), _createBlock(_component_form_generator_analysis, {
                    key: 1,
                    "fill-id": (_ctx.data.params && _ctx.data.params.fillId) || '',
                    "show-preview-src-list-all": true
                  }, null, 8, ["fill-id"]))
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              ((_ctx.submitStatus != 'SUBMITTED' || !_ctx.submitStatus) && _ctx.projectState != 2)
                ? (_openBlock(), _createBlock(_component_form_generator_pc, {
                    key: 0,
                    ref: "formGeneratorMobile",
                    "form-id": (_ctx.data.params && _ctx.data.params.formId) || '959854787992489984',
                    "user-id": 
            (_ctx.data.params && _ctx.data.params.userId) || '1329655322796658689'
          ,
                    "fill-id": (_ctx.data.params && _ctx.data.params.fillId) || _ctx.middleDetailId,
                    "theme-color": "#1AB370",
                    "is-show-submit-btn": false,
                    "is-show-save-btn": false,
                    other: _ctx.data.other,
                    "submit-success-method": _ctx.submitSuccessFn,
                    "save-success-method": _ctx.saveSuccessFn,
                    "validate-method": _ctx.validateMethod,
                    "is-show-success-tips": false,
                    "show-preview-src-list-all": true
                  }, null, 8, ["form-id", "user-id", "fill-id", "other", "submit-success-method", "save-success-method", "validate-method"]))
                : (_openBlock(), _createBlock(_component_form_generator_analysis, {
                    key: 1,
                    "fill-id": (_ctx.data.params && _ctx.data.params.fillId) || '',
                    "show-preview-src-list-all": true
                  }, null, 8, ["fill-id"]))
            ])),
        (_ctx.config.isMobile)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              ((_ctx.submitStatus != 'SUBMITTED' || !_ctx.submitStatus) && _ctx.projectState != 2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("button", {
                      class: _normalizeClass(!_ctx.isJoin ? 'form-button' : 'form-button isJoin'),
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)))
                    }, " 提交 ", 2),
                    (!_ctx.isJoin)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "form-button save-button",
                          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.saveForm && _ctx.saveForm(...args)))
                        }, " 保存 "))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              ((_ctx.submitStatus != 'SUBMITTED' || !_ctx.submitStatus) && _ctx.projectState != 2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (!_ctx.isJoin)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "case-button case-backButton",
                          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.saveForm && _ctx.saveForm(...args)))
                        }, " 保存 "))
                      : _createCommentVNode("", true),
                    _createElementVNode("button", {
                      class: "case-button mr",
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)))
                    }, " 提交 "),
                    _createElementVNode("button", {
                      class: "case-button case-backButton",
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleBack && _ctx.handleBack(...args)))
                    }, " 返回 ")
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("button", {
                      class: "case-button case-backButton",
                      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleBack && _ctx.handleBack(...args)))
                    }, " 返回 ")
                  ]))
            ], 64))
      ]))
    : _createCommentVNode("", true)
}