const envConfig = {
	dev: { // dev分支的环境配置
		meetServerUrl: 'https://meeting-dev-api.100url.cn',
    middleServerUrl: 'https://mg.100api.cn',
		imEnv: 'dev',
	  meetAgendasUrl: 'https://meeting-dev.100url.cn',
		signalUrl: 'live.100tt.com.cn/signal-server',
		reportLogUrl: 'https://meeting-dev-api.100url.cn/ybmeet-log/logan/web/upload.json',
		adminUrl: 'https://admin.test.100meet.cn'
	},
	test: { // test分支的环境配置

		meetServerUrl: 'https://api.test.100meet.cn',
    middleServerUrl: 'https://api.test.100meet.cn',
		imEnv: 'test',
		meetAgendasUrl: 'https://www.test.100meet.cn',
		signalUrl: 'signal.test.100rtc.com/signal-server',
		reportLogUrl: 'https://api.test.100meet.cn/ybmeet-log/logan/web/upload.json',
		adminUrl: 'https://admin.test.100meet.cn',
	},
	prod: { // master分支的环境配置
		meetServerUrl: 'https://api.100meet.cn',
    middleServerUrl: 'https://api.100meet.cn',
		imEnv: 'prod',
		meetAgendasUrl: 'https://web.100meet.cn',
		signalUrl: 'signal.100rtc.com/signal-server',
		reportLogUrl: 'https://api.100meet.cn/ybmeet-log/logan/web/upload.json',
		adminUrl: 'https://admin.100meet.cn'
	}
}

const env = process.env.VUE_APP_ENV || 'dev'

export const meetServerUrl = envConfig[env].meetServerUrl
export const middleServerUrl = envConfig[env].middleServerUrl
export const imEnv = envConfig[env].imEnv
export const meetAgendasUrl = envConfig[env].meetAgendasUrl
export const signalUrl = envConfig[env].signalUrl
export const reportLogUrl = envConfig[env].reportLogUrl
export const adminUrl = envConfig[env].adminUrl
