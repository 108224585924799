import axios from 'axios'
import qs from 'qs'

let channelToken = ''
export function setAxiosChannelToken(val) {
  channelToken = val
}

// 创建axios实例
// axios.defaults.headers.post['Content-Type'] = 'application/json'
export function create (apiBaseUrl) {
  const service = axios.create({
    baseURL: apiBaseUrl,
    timeout: 50000, // 请求超时时间
    withCredentials: true
  })
  // request拦截器
  service.interceptors.request.use(
    config => {
      // 设置参数
      if (!config.headers['Content-Type']) {
        config.headers = {
          'Content-Type': 'application/json'
        }
      }
      // Do something before request is sent

      if (channelToken) {
        config.headers['X-Channel-Token'] = channelToken
      }
      return config
    },
    error => {
      // Do something with request error
      Promise.reject(error)
    }
  )
  // respone拦截器
  service.interceptors.response.use(
    response => {
      const data = response.data
      if (data) {
        if (data.code === '200' || data.code === 200) {
          // 200表示请求成功
          // } else if (data.code === '403' || data.code === '406') {
        } else {
          if (data.msg) {
          }
          return Promise.reject(data)
        }
      }
      return data
    },
    error => {
      console.log('error')
      if (error.response && error.response.status === 400) {
      }
      return Promise.reject(error)
    }
  )
  return service
}

export default {
  put (url, data = {}) {
    return new Promise((resolve, reject) => {
      create('').put(url, data)
        .then(response => {
          if (response.code === 200 || response.code === '200') {
            resolve(response.data)
          } else {
          }
        }, err => {
          reject(err)
        })
    })
  },
  post (url, data = {}) {
    return new Promise((resolve, reject) => {
      create('').post(url, data)
        .then(response => {
          if (response.code === 200 || response.code === '200') {
            resolve(response.data)
          } else {
          }
        }, err => {
          reject(err)
        })
    })
  },
  get (url, data = {}) {
    return new Promise((resolve, reject) => {
      create('').get(url, data)
        .then(response => {
          if (response.code === 200 || response.code === '200') {
            resolve(response.data)
          } else {
          }
        }, err => {
          reject(err)
        })
    })
  },
  del (url, data = {}) {
    return new Promise((resolve, reject) => {
      create('').delete(url, data)
        .then(response => {
          if (response.code === 200 || response.code === '200') {
            resolve(response.data)
          } else {
          }
        }, err => {
          reject(err)
        })
    })
  },
  form (url, formData = {}) {
    let config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    return new Promise((resolve, reject) => {
      create('').post(url, qs.stringify(formData), config)
        .then(response => {
          if (response.code === 200 || response.code === '200') {
            resolve(response.data)
          } else {
          }
        }, err => {
          reject(err)
        })
    })
  }
}
