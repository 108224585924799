import { ISwitcherBypass } from '@/typings/live';
import { Module } from "vuex";
import { IGlobalState } from '..';
import * as Types from '@/store/action-types'
import Cookies from 'js-cookie'

const state: ISwitcherBypass = {
    switcherId: '', // 导播台ID
    RTCScourceId: 0,
    officeImgScourceId: 0, // PPT图片源组件ID
    textBgScourceId: 0, // 字幕背景图片源组件ID
    outputScourceId: 0,
    templateId: 0,
    cgWordScourceId: 0, // CG字幕源组件ID
    cgObjectId: 0 // CG字幕实例ID
}

const switcher: Module<ISwitcherBypass, IGlobalState> = {
    namespaced: true,
    state,
    mutations: {
        [Types.SET_SWITCHER_ID](state, payload) {
            state.switcherId = payload
            Cookies.set('switcherId', payload)
        },
        [Types.SET_RTC_COMPONENT_ID](state, payload) {
            state.RTCScourceId = payload
        },
        [Types.SET_OFFICE_IMG_COMPONENT_ID](state, payload) {
            state.officeImgScourceId = payload
        },
        [Types.SET_WORD_BG_IMG_COMPONENT_ID](state, payload) {
            state.textBgScourceId = payload
        },
        [Types.SET_OUTPUT_COMPONENT_ID](state, payload) {
            state.outputScourceId = payload
        },
        [Types.SET_TEMPLATE_COMPONENT_ID](state, payload) {
            state.templateId = payload
        },
        [Types.SET_CG_COMPONENT_ID](state, payload) {
            state.cgWordScourceId = payload
        },
        [Types.SET_CG_OBJECT_ID](state, payload) {
            state.cgObjectId = payload
        },
    },
    actions: {
        [Types.CLEARN_SWITCHER_STATE](context) {
            context.commit(Types.SET_SWITCHER_ID, 0)
            context.commit(Types.SET_RTC_COMPONENT_ID, 0)
            context.commit(Types.SET_OFFICE_IMG_COMPONENT_ID, 0)
            context.commit(Types.SET_WORD_BG_IMG_COMPONENT_ID, 0)
            context.commit(Types.SET_OUTPUT_COMPONENT_ID, 0)
            context.commit(Types.SET_TEMPLATE_COMPONENT_ID, 0)
            context.commit(Types.SET_CG_COMPONENT_ID, 0)
            context.commit(Types.SET_CG_OBJECT_ID, 0)
        }
    }
}

export default switcher