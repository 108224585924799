export const checkMobile = (value) => {
  return /^(13[0-9]|14[56789]|16[567]|15[012356789]|17[012356789]|18[0-9]|19[189])[0-9]{8}$/.test(value)
}

export const checkCode = (value) => {
  return /^\d{4}$/.test(value)
}

export const checkEmail = (value) => {
  //([0-9A-Za-z\\-_\\.]+)@([0-9a-z]+\\.[a-z]{2,3}(\\.[a-z]{2})?)
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(value)
}
export const checkPassword = (value) => {
  return /^([0]|[1-9][0-9])\d{4,6}$/.test(value)
}
export const checkchangePwd = (value) => {
  return /^\d{8,16}$/.test(value)
}
export const checkSmallPwd = value => {
  return /^\d{4,6}$/.test(value)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
 export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/**
 * @param {number} arg
 * @returns {Boolean}
 */
 export function isNumber(arg) {
  if (typeof Number.isInteger === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Number]'
  }
  return Number.isInteger(arg)
}