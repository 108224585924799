export const SDK_TAG_NAME = 'YB-MEET-SDK'

export const SDK_ERROR =
{
    // 成功
    SUCCESS : 0,
    INIT_FAILED : 1,
    // 参数错误
    PARAMETERS : 2,
    // 超出范围错误
    OUT_OF_RANGE : 10000,
    // 不存在错误
    NO_EXIST : 20000,
    // 手机号码格式错误
    PHONE_FORMAT : 3,
    // 邮箱格式错误
    EMAIL_FORMAT : 4,
    // 手机未注册
    PHONE_NOT_REGISTER : 5,
    // 已发送手机验证码
    VERIFYCODE_SENT : 6,
    // 邮箱未注册
    EMAIL_NOT_REGISTER : 7,
    // 服务器返回的一个错误
    CODE_FROM_SERVER : 8,
    // 帐号被停用
    ACCOUNT_SUSPENDED : 9,
    // 用户名或密码错误，请检查后重试
    ACCOUNT_PASSWORDERROR : 10,
    // 错误的验证码
    WRONG_VERIFYCODE : 11,
    // 登录失败
    LOGIN_FAILED : 12,
    // 登录成功
    LOGIN_SUCCESS : 13,
    // 还未登录
    NOT_LOGIN : 14,
    // 获取用户信息失败，请检查后重试
    GET_USERINFO : 16,
    // 修改用户名失败
    CHANGE_USERNAME : 17,
    // 原密码和新密码一致
    OLDPASSWORD_EQUAL_NEWPASSWORD : 18,
    // 原密码或新密码为空
    OLDPASSWORD_OR_NEWPASSWORD_EMAPTY : 19,
    // 改密码失败
    CHANGE_PASSWORD : 20,
    // 修改个人会议号信息失败
    CHANGE_PERSONAL_MEETINGINFO : 21,
    // 密码格式错误
    PASSWORD_FORMAT : 22,
    // 创建会议失败
    SDKERR_CREATE_MEETING : 23,
    // 当前有正在召开的会议
    SDKERR_MEETING_NOT_END : 24,
    // 修改会议失败
    SDKERR_MODIFY_MEETING : 25,
    // 获取会议信息失败
    SDKERR_GET_MEETINGINFO : 26,
    // 删除会议失败
    SDKERR_DELETE_MEETING : 27,
    // 删除历史会议失败
    SDKERR_DELETE_HISTORY_MEETING : 28,
    // 删除会议时，会议正在进行中
    SDKERR_DELETE_OPENNING_MEETING : 29,
    // 获取预约会议列表错误
    SDKERR_GET_SCHDULE_MEETING_LIST : 30,
    // 获取历史会议列表错误
    SDKERR_GET_HISTORY_MEETING_LIST  : 31,
    // 获取今日会议列表错误
    SDKERR_GET_TODAY_MEETING_LIST : 32,
    // 新增议程错误
    SDKERR_CREATE_MEETING_AGENDA : 33,
    // 获取议程地址错误
    SDKERR_GET_AGENDA_URL : 34,

    // // 开始会议失败
    // SDKERR_START_MEETING,
    // // 加入会议失败
    // SDKERR_JOIN_MEETING,
    // // 会议号不存在
    // SDKERR_MEETINGNO_NO_EXIST,
    // // 会议已结束
    // SDKERR_MEETING_ENDED,
    // // 会议已锁定
    // SDKERR_MEETING_LOCKED,
    // // 会议人数已满
    // SDKERR_MEETING_OVERFLOW,
    // // 会议密码错误
    // SDKERR_INVALID_MEETING_PASSWORD,
    // // 请输入会议密码
    // SDKERR_INPUT_MEETING_PASSWORD,
    // // 等待主持人入会
    // SDKERR_WAIT_HOST_ENTER,
    // // 进入房间失败
    // SDKERR_ENTER_ROOM,
    // // 打开本地麦克风失败
    // SDKERR_OPEN_MIC,
    // // 关闭本地麦克风失败
    // SDKERR_CLOSE_MIC,
    // // 打开摄像头失败
    // SDKERR_OPEN_CAMERA,
    // // 没有权限
    // SDKERR_INVALID_AUTHORITY,
}

export const LOGIN_TYPE =
{
    NONE : 0,					// 非登录用户
    PERSONAL_PASSWORD : 1,		// 个人密码登录
    PERSONAL_VERIFYCODE : 2,		// 个人验证码登录
    ENTERPRISE : 3				// 企业邮箱登录
}

export const LANG_ZH = 'zh-CN'
export const LANG_EN = 'en-US'
export const LANG_AUTO = 'auto'

// 最大分享帧率
export const MAX_SHARE_VIDEO_FPS = 10

//音频设备默认音量值
export const DEFAULT_VOLUME = 100

// 缓存配置的最大数目
export const MAX_SETTING_CACHE_COUNT = 50

//测试网络 图片url常量
export const NETWORK_TEST_URL = 'https://www.100doc.com.cn/themes/simpleboot3/public/assets/images/top_logo.png'