import { EventEmitter } from 'events'
import Logger from './logger'
import { SDK_TAG_NAME } from '../constant'

const logger = new Logger(
	`${SDK_TAG_NAME} EnhancedEventEmitter`
);

export class EnhancedEventEmitter extends EventEmitter
{
	constructor()
	{
		super();
		this.setMaxListeners(Infinity);
	}

	safeEmit(event, ...args)
	{
		const numListeners = this.listenerCount(event);

		try
		{
			return this.emit(event, ...args);
		}
		catch (error)
		{
			logger.error(
				'safeEmit() | event listener threw an error [event:%s]:%o',
				event, error);

			return Boolean(numListeners);
		}
	}

	async safeEmitAsPromise(event, ...args)
	{
		return new Promise((resolve, reject) =>
		{
			try
			{
				this.emit(event, ...args, resolve, reject);
			}
			catch (error)
			{
				logger.error(
					'safeEmitAsPromise() | event listener threw an error [event:%s]:%o',
					event, error);

				reject(error);
			}
		});
	}
}
