/*
 * Sun-Sky  2022/4/24 下午3:53
 */

import { sha256 } from "js-sha256";
import { Base64 } from "js-base64";
import store from "@/store";
import { Toast } from "vant";
import { getRegion } from "@/api/user";
import { getToken } from "@/utils/storage";

export class util {
  /*
   * router历史记录
   * */
  static routerHistory(route: any) {
    const history: any = [];
    if (history[history.length - 1] !== route.path) {
      history.unshift(route.fullPath);
    }
    if (history.length > 20) {
      history.splice(20, 1);
    }
    store.commit("SET_ROUTER_HISTORY", history);
  }

  /**
   * @param time 时间戳
   * @param format 默认yyyy-MM-dd hh:mm
   */
  static changeTime(time: any, format?: string) {
    if (!time) return null
    time = Number(time);
    const fmt = format ? format : "yyyy.MM.dd hh:mm";
    const date = new Date(time);
    return formatDate(date, fmt);
  }
  /*
   * @isPhone 验证手机号
   * */
  static isPhone(phone: any) {
    //目前宽泛支持即可
    return /^1[3456789]\d{9}$/.test(phone)
    // // 验证手机号
    // const reg: any =
    //   /^(0|86|17951)?(13[0-9]|16[567]|15[0-9]|17[0-9]|18[0-9]|19[0-9]|14[5-9])[0-9]{8}$/;
    // if (reg.test(phone)) return true;
    // if (!reg.test(phone)) return false;
  }
  /*
   * @passwordVerify 验证密码是否合规
   * */
  // static passwordVerify(password: any) {
  //   const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
  //   if (reg.test(password)) return true;
  //   if (!reg.test(password)) return false;
  // }
  /*
   * @sha256AndBase64 sha256 + base64加密
   * */
  static sha256AndBase64(value: any) {
    if (!value) return;
    let item = sha256(value);
    item = Base64.encode(item);
    return item;
  }
  /*
   * @enoughLength 判断字符串位数
   * */
  static enoughLength(str: any, length: any) {
    return str.length >= length;
  }
  /*
   * @validatPlatName 判断汉字、英文和数字
   * */
  static validatPlatName(str: any) {
    str = String(str);
    const reg = /^[0-9a-zA-Z\s\u4e00-\u9fa5]+$/;
    return reg.test(str.trim());
  }

  /*
   * @getTitleList 获取职称 职称类别:1:医生职称类别 2:护士职称类别 3:药师职称类别 4:技师职称类别
   * */
  static async getTitleList(type: string) {
    const arr = ["1", "2", "3", "4"];
    let _arr = [];
    if (arr.includes(type)) {
      // let _type: string = ''
      // switch (type) {
      //   // 类型映射
      //   // 1:医生职称类别 2:药师职称类别 3:护士职称类别 4:技师职称类别
      //   case '2':
      //     _type = '3'
      //     break
      //   case '3':
      //     _type = '2'
      //     break
      //   default:
      //     _type = type
      // }
      const par = {
        type: type,
        domain: "misc",
        pageNum: 1,
        pageSize: 999,
      };
      const res:any = await getRegion(par);
      const { code, data, msg } = res
      if (code === 200) {
        _arr = data.list;
      } else {
        Toast(msg);
      }
    }
    return _arr;
  }
  static throttle(fn: any, wait: number) {
    let timer: any = null;
    return function () {
      // @ts-ignore
      const context = this;
      const args = arguments;
      if (!timer) {
        timer = setTimeout(function () {
          fn.apply(context, args);
          timer = null;
        }, wait);
      }
    };
  }
}
function formatDate(tDate: any, fmt: any) {
  /*
   * 时间戳格式化
   * */
  const o: any = {
    "M+": tDate.getMonth() + 1, // 月份
    "d+": tDate.getDate(), // 日
    "h+": tDate.getHours(), // 小时
    "m+": tDate.getMinutes(), // 分
    "s+": tDate.getSeconds(), // 秒
    "q+": Math.floor((tDate.getMonth() + 3) / 3), // 季度
    S: tDate.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (tDate.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (const k in o) {
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  }
  return fmt;
}

export function loadScript(url: string, callback?: Function, async?: boolean) {
  let script: any = document.createElement("script");
  let scriptLoaded = false;
  script.onload = script.onreadystatechange = function () {
    if (scriptLoaded) {
      return;
    }
    const readyState = script.readyState;
    if (
      "undefined" === typeof readyState ||
      readyState === "loaded" ||
      readyState === "complete"
    ) {
      scriptLoaded = true;
      try {
        if (typeof callback === "function") {
          callback();
        }
      } finally {
        script.onload = script.onreadystatechange = null;
        // script.parentNode.removeChild(script);
        script = null;
      }
    }
  };
  script.type = "text/javascript";
  script.charset = "utf-8";
  script.async = !!async;
  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
}
// 视频观看相关的
export function  getEnv(){
    let env:any = {}
    switch(process.env.VUE_APP_ENV){
        case 'dev': env = {
            cloudBucket: 'poster-1253412830',
            cloudBucketRegion: 'ap-beijing',
            cloudBucketAppid: '1500011287',
            trackApi: 'http://dotting.cmalive.cn/1c02f62954e3217c0682fcdf5dc9ba13/wow.gif?'
        };break;
        case 'test': env = {
            cloudBucket: 'poster-1257099670',
            cloudBucketRegion: 'ap-beijing',
            cloudBucketAppid: '1500012576',
            trackApi: 'http://dotting.cmalive.cn/ab4e1f4a245aaf11c45a0fbb8b7fd708/wow.gif?'
        };break;
        case 'prod': env = {
            cloudBucket: 'poster-1304421192',
            cloudBucketRegion: 'ap-beijing',
            cloudBucketAppid:  '1304421192',
            trackApi: 'https://dotting.cmalive.cn/0edaaa2df9d707af49b5d7f8c6040999/wow.gif?'
        };break;
        
    }
    return env;
}

export function isMobile() {
  const userAgentInfo = navigator.userAgent;
  const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
  const getArr = Agents.filter(i => userAgentInfo.includes(i));
  return getArr.length ? true : false;
}

export function isUndef(v:any) {
  return v === undefined || v === null
}

export function isDef(v:any) {
  return v !== undefined && v !== null
}

