/*
 * Sun-Sky  2022/4/22 下午7:18
 */

import { Commit } from 'vuex'
import { getCompanyConfig } from '@/api/video'
import router from '@/router/index'
import { getToken } from '@/utils/storage'

export default {
  state() {
    return {
      companyConfig: {}, // 租户信息
    }
  },
  getters: {
    companyConfig: (state: any) => state.companyConfig,
  },
  mutations: {
    SET_COMPANY_CONFIG(state: any, data: any) {
      state.companyConfig = data
    },
  },
  actions: {
    async setCompanyConfig({ commit }: { commit: Commit }) {
      // 租户信息
      let res: any = await getCompanyConfig()
      let { data } = res as { data: any }
      let link: any = document.head.querySelector('link[rel=icon]') // 设置浏览器icon
      link.href = data.logoUrl
      setTheme(data.topicColor)
      commit('SET_COMPANY_CONFIG', data)
    },
  },
}

function setTheme(color: string) {
  const themeStyle = document.getElementsByTagName('html')[0].style
  if (!themeStyle) return
  themeStyle.setProperty('--mobile-theme-color', color)
}
