import SocketPlugin from '../api/socket-plugin'
import {joinRoom, getUserList, getRoomInfo, sendMsg, getRoomHeat, updateRoomHeat, getRoomHistory, sendPrivateMsg, updateNickAndAvatar} from '../api/im'
import EventEmitter from 'events'
import IM_EVENT from "../api/im-events";
import Env from "../util/env";
import global from "../util/global"
import { setAxiosChannelToken } from "../util/request";

export class Im {
    constructor(config) {
        //this.roomID = config.roomID
        //this.pwd = config.pwd
        //this.nickname = config.nickname

        console.error('im env ------------');
        console.log(config.env);
        global.env = config.env || 'prod'
        this._emitter = new EventEmitter()
        this.socketPlugin = null
        if (config.autoSocket === undefined || config.autoSocket === null || config.autoSocket === ''){
            this.autoSocket = true
        }else{
            this.autoSocket = config.autoSocket  //socket 进行自动连接
        }
    }

    /**
     * 获取在线用户信息
     * @param roomId
     */
    getUserList(roomId){
        return new Promise(((resolve, reject) => {
            getUserList(roomId).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        if (res.data['users']) {
                            let onLineUsers = res.data['users'].filter(v => v !== null)
                            resolve(onLineUsers)
                        }
                    }
                }
            }, err => {
                reject(err)
            })
        }))
    }

    /**
     * 获取房间信息
     */
    getRoomInfo(roomId){
        return new Promise(((resolve, reject) => {
            getRoomInfo(roomId).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data)
                    }
                }
            }, err => {
                reject(err)
            })
        }))
    }

    /**
     * 发送文本消息
     * @param data
     * @returns {Promise<unknown>}
     */
    sendTextMessage(roomId, data){
        return new Promise(((resolve, reject) => {
            sendMsg(roomId, data).then((res)=>{
                if (res.code === 200){
                    resolve()
                }
            }, err => {
                reject(err)
            })
        }))
    }

    /**
     * 获取房间热度
     * @returns {Promise<unknown>}
     */
    getRoomHeat(roomId){
        return new Promise(((resolve, reject) => {
            getRoomHeat(roomId).then(res=>{
                if (res.code === 200){
                    resolve(res.data)
                }
            })
        }))
    }

    /**
     * 更新热度
     * click 没多少秒点击的次数
     * @returns {Promise<unknown>}
     */
    updateRoomHeat(roomId, click) {
        return new Promise(((resolve, reject) => {
            updateRoomHeat(roomId, {click: click}).then(res=>{
                if (res.code === 200){
                    resolve()
                }
            })
        }))
    }

    /**
     * 更新昵称和头像
     * @param data
     * @returns {Promise<unknown>}
     */
    updateNickAndAvatar(data) {
        return new Promise(((resolve, reject) => {
            updateNickAndAvatar(data).then(res=>{
                if (res.code === 200){
                    resolve()
                }
            })
        }))
    }

    /**
     * 获取聊天记录
     * @param roomId
     * @param start
     * @param end
     * @param page
     * @param size
     * @returns {Promise<unknown>}
     */
    getRoomHistory(roomId, start, end, page, size){
        return new Promise(((resolve, reject) => {
            getRoomHistory(roomId, start, end, page, size).then((res)=>{
                if (res.code === 200){
                    let list = []
                    const historyList = res.data &&  res.data['records'] || []
                    if (historyList){
                        // const historyList = res.data['records'] || []
                        historyList.forEach((item, index) => {
                            let obj = {
                                contentType: item['content']['type'], //1是文字，2是图片
                                text: item['content']['payload'],
                                avatar: item['metaData']['from'] && item['metaData']['from']['avatar'],
                                id: item['metaData']['id'],
                                timestamp: item['metaData']['timestamp'],
                                nickname: item['metaData']['from'] && item['metaData']['from']['nickname'],
                                uid: item['metaData']['from'] && item['metaData']['from']['uid'],
                                seq: item['metaData']['from'] && item['metaData']['from']['seq'],
                                admin: item['metaData']['from'] && item['metaData']['from']['admin'],
                                // specified: item['metaData']['to']['specified'], //@用户
                                type: item['metaData']['to']['type'] //type是0是单聊，type是2是群发消息
                            }
                            list.push(obj)
                        })
                    }
                    resolve(list)
                }
            }, err => {
                reject(err)
            })
        }))
    }

    /**
     * 发送私聊消息
     * @param data
     * @returns {Promise<unknown>}
     */
    sendPrivateMsg(roomId, target, data){
        return new Promise(((resolve, reject) => {
            sendPrivateMsg(roomId, target, data).then((res)=>{
                if (res.code === 200){
                    resolve()
                }
            }, err => {
                reject(err)
            })
        }))
    }

    /**
     * 连接websocket
     * @param token
     */
    connectSocket(roomId, token){
        const socketApi = Env[global.env]['SOCKET_API']
        console.log('calling IMSDK -> connectSocket() | env', global.env, '| socketApi', socketApi)
        setAxiosChannelToken(token)

        let socketConfig = {
            url:`${socketApi}?token=${token}&room=${roomId}`
        }
        this.socketPlugin = new SocketPlugin(socketConfig)
        this.socketPlugin.connect()

        this.attachEvents()
    }

    /**
     * 退出房间
     */
    exitRoom(){
      this.socketPlugin && this.socketPlugin.wsOut()
    }

    /**
    * 绑定事件
    **/
    attachEvents(){
        this.socketPlugin.on(IM_EVENT.USER_JOIN, this.onUserJoin.bind(this))

        this.socketPlugin.on(IM_EVENT.TEXT_MESSAGE_RECEIVED, this.onTextMessageReceived.bind(this))

        this.socketPlugin.on(IM_EVENT.MANAGER_GROUP_SEND_MESSAGE_RECEIVED, this.onGroupSendMessageReceived.bind(this))

        this.socketPlugin.on(IM_EVENT.IMG_MESSAGE_RECEIVED, this.onImgMessageReceived.bind(this))

        this.socketPlugin.on(IM_EVENT.KICKED_OUT_RECEIVED, this.onKickedOutReceived.bind(this))

        this.socketPlugin.on(IM_EVENT.SOCKET_CONNECT_FAIL, this.onSocketConnectFail.bind(this))

        this.socketPlugin.on(IM_EVENT.USER_LEAVE, this.onUserLeave.bind(this))

        this.socketPlugin.on(IM_EVENT.USER_TIPS, this.onUserTips.bind(this))

        // 新增踢人
        this.socketPlugin.on(IM_EVENT.REMOVE_SELF, this.onRemoveSelf.bind(this))
    }

    /**
     * 删除事件
     */
    dettachEvents(){
        this.socketPlugin.off(IM_EVENT.USER_JOIN, this.onUserJoin.bind(this))

        this.socketPlugin.off(IM_EVENT.TEXT_MESSAGE_RECEIVED, this.onTextMessageReceived.bind(this))

        this.socketPlugin.off(IM_EVENT.KICKED_OUT_RECEIVED, this.onKickedOutReceived.bind(this))

        this.socketPlugin.off(IM_EVENT.SOCKET_CONNECT_FAIL, this.onSocketConnectFail.bind(this))

        this.socketPlugin.off(IM_EVENT.USER_LEAVE, this.onUserLeave.bind(this))

        this.socketPlugin.off(IM_EVENT.USER_TIPS, this.onUserTips.bind(this))

        this.socketPlugin.off(IM_EVENT.MANAGER_GROUP_SEND_MESSAGE_RECEIVED, this.onGroupSendMessageReceived.bind(this))

        // 新增踢人
        this.socketPlugin.off(IM_EVENT.REMOVE_SELF, this.onRemoveSelf.bind(this))
    }

    onUserJoin(){
        this._emitter.emit(IM_EVENT.USER_JOIN)
    }

    onTextMessageReceived(data){
        this._emitter.emit(IM_EVENT.TEXT_MESSAGE_RECEIVED, data)
    }

    onGroupSendMessageReceived(data){
        this._emitter.emit(IM_EVENT.MANAGER_GROUP_SEND_MESSAGE_RECEIVED, data)
    }

    onImgMessageReceived(data){
        this._emitter.emit(IM_EVENT.IMG_MESSAGE_RECEIVED, data)
    }

    onKickedOutReceived(){
        this.dettachEvents()
        this._emitter.emit(IM_EVENT.KICKED_OUT_RECEIVED)
    }

    onSocketConnectFail(){
        this.dettachEvents()
        this._emitter.emit(IM_EVENT.SOCKET_CONNECT_FAIL)
    }

    onUserLeave(){
        this._emitter.emit(IM_EVENT.USER_LEAVE)
    }

    onUserTips(data){
        this._emitter.emit(IM_EVENT.USER_TIPS, data)
    }

    /**
     * 踢出自己
     */
    onRemoveSelf(){
        this._emitter.emit(IM_EVENT.REMOVE_SELF)
    }

    /**
     * 添加自定义事件监听
     * @param event
     * @param listener
     */
    on(event, listener) {
        this._emitter.addListener(event, listener)
    }

    /**
     * 添加一次性自定义事件监听
     * @param event
     * @param listener
     */
    once(event, listener) {
        this._emitter.once(event, listener)
    }

    /**
     * 移除事件自定义监听
     * @param event
     * @param listener
     */
    off(event, listener) {
        this._emitter.removeListener(event, listener)
    }
}