export interface IYbBaseInfo extends IYbUserInfo {
    roomid: number | string,
    peerId: string, // 会议服务器用户Id -- roomid + '_' + userid
    conferenceNo: number | string // 会议ID
}

export interface IYbUserInfo {
    [key: string]: string | number
    userid: string,
    userName: string,
    ownerName: string,
    roleName: string,
    roleCode: string,
    avatarUrl?: string | any,
}

export interface IYbJoinMeet {
    conferenceNo: string, // 会议ID
    userName: string,
    closeConference: boolean,
    password?: string
}

export interface IYbMeetInfo {
    [key: string]: number | string | object
    roomid: string,
    peerId: string,
    agendaPermission: number, // 议议程权限 0：所有人可见 1：仅主持人可见
    allMuteState: number, // 全局的静音状态  0：全体解除静音  1：全体静音
    allowEarlyEntry: number, // 是否允许提前进入  1：是；0否；
    // allowOutsideWhite: number,
    allowSelfUnmute: number, // 允许成员自我解除静音 1是；0：否
    // blackEnable: number,
    conferenceNo: string,
    // id: number,
    links: string, // 邀请链接
    lockedState: number, // 会议全局的锁定状态： 0未锁定；1锁定
    muteJoinMeeting: number, // 成员加入会议静音 1：是；0否；
    ownerId: string, // 会议所有者id
    ownerName: string, // 会议所有者name
    ownerPasswordEnable: number, // 是否有主持人密码，0否，1是
    password: string, //会议密码
    passwordEnable: number, // 是否使用密码，0否，1是
    playTips: number, // 成员加入会议时播放提示因 1：是；0否；
    recordPermission: number, // 录制权限  0：所有人可录制  1：仅主持人可录制
    // screenShot: number,
    sharePermission: number, // 共享权限  0：所有人可共享  1：仅主持人可共享
    title: string,
    liveDetails: object // 直播详情信息---saas业务接口返回数据
}

export enum YB_TOKEN {
    currencyToken = 'Currency-Token', // 通用Token
    imToken = 'X-Channel-Token', // IM token
    meetToken = 'X-Conference-Token', // 会议服务token
}

type onDeviceGarant = {
    onNoCameraGrant: Function,
    onNoMicGrant: Function
}
type onEnterRoom = {
    onEnteredMeeting: Function,
    onExitMeeting: Function,
    onRemoteUserEnterMeeting: Function,
    onRemoteUserLeaveMeeting: Function
}
type onGlobalError = {
    onNetworkQuality: Function,
    onWebsocketMayDisconnected: Function,
    onDisconnected: Function,
    onWebsocketStillAlive: Function
    onConnectionTimeOut: Function
    onError: Function
    onEnterRoomError: Function
}
export interface IYbRtcListeners {
    mediaDeviceGarant: onDeviceGarant,
    enterLiveRoom: onEnterRoom,
    globalError: onGlobalError
}

export interface IYbImTextMessage {
    admin: boolean,
    avatar: string,
    id: string,
    nickname: string,
    seq: number,
    specified: ""
    text: string,
    timestamp: number,
    type: number,
    uid: string, // userId
}