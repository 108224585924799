import * as Types from '@/store/action-types';
import { ILiveState, LIVE_STATUS, DOC_STATUS, LAYOUT_TEMPLATE} from "@/typings/live"
import { Module } from "vuex"
import { IGlobalState } from '..'

const state: ILiveState = {
    liveStatus: LIVE_STATUS.WAIT_LIVING, // 直播状态
    docStatus:  DOC_STATUS.LECTURING_WAIT, // 文档演示状态
    docDemonstrateList: [ // 当前演示的文档列表
        'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
        'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg'
    ],
    currentDemonDoc:'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
    layoutTemplate: LAYOUT_TEMPLATE.BASE, // 当前布局模版
    mediaDeviceStatus: {  // 媒体设备 打开/关闭状态
        microphone: false,
        camera: false
    },
    mediaDeviceAuthorizeStatus: { // 媒体设备 授权/未授权状态
        microphone: false,
        camera: false
    },
    volumeSize: 0, //麦克风音量大小
    barrageChatVisible: true, //聊天弹幕显隐状态
    alreadyAudioAisle: false, //是否已经建立音频通道
    pushStreamUrl:'', // 导播台推流地址
    liveVideoId: "", // 直播（列表）任务Id
    liveProjectId:'',//直播项目id
    liveUserId:'',//直播用户ID
    commandBarVisiable: true, // 底部工具栏显示隐藏状态
    disabledInitLock:true, //直播状态初始化操作禁用锁
    currentDemoDocId: '', // 当前演示的文档任务id
    isActivePuase: false, //是否为主动暂停直播
    disTouchmove:false, //是否禁止滑动事件解决ios下拉问题
}
/**
 * @Module params 1 自己状态; params 2 全局状态
 */
const live: Module<ILiveState, IGlobalState> = {
    namespaced: true,
    state,
    mutations:{
        [Types.SET_LAYOUT_TEMPLATE](state,payload){
            state.layoutTemplate = payload
        },
        [Types.SET_LIVE_STATUS](state,payload){
            if(typeof payload === 'number'){
                state.liveStatus = payload;
                state.isActivePuase = false;
            }else if (typeof payload === 'object'){
                state.liveStatus = payload.status;
                state.isActivePuase = payload.isActive;
            }
        },
        [Types.SET_DOC_STATUS](state,payload){
            state.docStatus = payload
        },
        [Types.SET_DOC_DEMONSTRATE_LIST](state,payload){
            state.docDemonstrateList = payload
        },
        [Types.SET_DEVICE_AUTHORIZE_STATUS](state,payload){
            state.mediaDeviceAuthorizeStatus = payload
        },
        [Types.UPDATE_AUDIO_AISLE](state,payload){
            state.alreadyAudioAisle = payload
        },
        [Types.SET_MEDIA_DEVICE_STATUS](state,payload){
            if(payload.microphone !== undefined) {
                state.mediaDeviceStatus.microphone = payload.microphone
            }else if(payload.camera !== undefined){
                state.mediaDeviceStatus.camera = payload.camera
            }
        },
        [Types.UPDATE_BABERRAGE_VISIBLE](state, payload){
            state.barrageChatVisible = payload
        },
        [Types.UPDATE_CURRENT_DEMON_DOC](state, payload){
            state.currentDemonDoc = payload
        },
        [Types.SET_PUSH_STREAM_URL](state, payload){
            state.pushStreamUrl = payload
        },
        [Types.SET_LIVE_TASK_ID](state, payload){
            state.liveVideoId = payload
        },
        [Types.SET_LIVE_PROJECT_ID](state, payload){
            state.liveProjectId = payload
        },
        [Types.SET_LIVE_USER_ID](state, payload){
            state.liveUserId = payload
        },
        [Types.SET_COMMAND_BAR_VISIABLE](state, payload){
            state.commandBarVisiable = payload
        },
        [Types.SET_DISABLED_LOCK](state, payload){
            state.disabledInitLock = payload
        },
        [Types.SET_CURRENT_DEMO_DOC_ID](state, payload){
            state.currentDemoDocId = payload
        },
        [Types.SET_VOLUME_SIZE](state, payload){
            state.volumeSize = payload
        },
        [Types.SET_DIS_TOUCHMOVE](state, payload){
            state.disTouchmove = payload
        }
    }
}

export default live