export default {
    'dev': {
        //开发环境
        //开发环境
        SOCKET_API: "wss://mg.100api.cn/chat",
        BASE_API: "https://mg.100api.cn/middle-im"
    },
    'test': {
        //测试环境
        SOCKET_API: "wss://api.test.100meet.cn/chat",
        BASE_API: "https://api.test.100meet.cn/middle-im"
    },
    'prod': {
        //线上环境
        SOCKET_API: "wss://api.100meet.cn/chat",
        BASE_API: "https://api.100meet.cn/middle-im"
    }
}