/**
 * result 错误信息实体对象
 */
export class ResultError extends Error {
  
  errorName;
  errorCode;
	constructor(errorName, errorCode, message) {
      super(message)
      this.name = 'ResultError'
      this.errorCode = errorCode
      this.errorName = errorName
    }
}

/**
 * 公共方法返回 实体
 */
export class ResultEntity {
  constructor(code, name, msg, data) {
    this.name = name
    this.code = code
    this.data = data
    this.msg = msg
  }
}