import "babel-polyfill"
import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import {draggable} from './directive/draggable'
import "normalize.css/normalize.css"
import "amfe-flexible"
import Vue3FormGenerator from 'vue3-form-generator'
import '@/assets/scss/vant-reset.scss'
import Vant from "vant"
const app = createApp(App);
app.use(Vue3FormGenerator, { bucketName: 'information-7-1259387452', regionEn: 'ap-beijing' ,baseApi: process.env.VUE_APP_ENV == 'prod'?'https://m-platform.100api.cn': 'https://mg.test.100api.cn'})
app.use(store).use(router)

app.use(Vant)

app.mount("#app")
app.directive('draggable', draggable)
