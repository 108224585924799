import Base from "../BasePlugin";
import Im from './lib'

class IM extends Base {
  constructor(app, config) {
    super(app);
    console.log('Im', config, app);

    this.imSDK = new Im({
      env: config.mode === 'master' ? 'prod' : config.mode,
    })
  }


    /**
   * 入房
   * @returns
   */
     async enterRoom(options = {}) {
      const { channelToken,roomid } = options

      return new Promise(async (resolve, reject) => {
        try {
          this.imSDK.connectSocket(roomid, channelToken)
          // this.getHistroyMsg(roomid)
          resolve()
        } catch (error) {
          console.error('calling webSDk -> enterRoom() | error', error)
          reject(error)
        }
      })
    }

    /**
     * 退房
     * @returns
     */
    async leaveRoom() {
      return new Promise(async (resolve, reject) => {
        try {
          this.imSDK.exitRoom()
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    }
     /**
     * 发送文本消息
     * @param data
     * @returns {Promise<unknown>}
     */
    sendTextMessage(roomId, data){
      return this.imSDK.sendTextMessage(roomId, data)
  }


    /**
     * 发送消息
     * @returns
     */
    async sendMsg(roomId, options = {}) {
      return new Promise(async (resolve, reject) => {
        try {
          const data = await this.imSDK.sendTextMessage(roomId, options)

          resolve(data)
        } catch (error) {
          console.error('calling webSDk -> sendMsg() | error', error)
          reject(error)
        }
      })
    }

    /**
     * 发送消息
     * @returns
     */
    async sendPrivateMsg(roomId, token, options = {}) {
      return new Promise(async (resolve, reject) => {
        try {
          const data = await this.imSDK.sendPrivateMsg(roomId, token, options)

          resolve(data)
        } catch (error) {
          reject(error)
        }
      })
    }

    /**
     *
     * @returns
     */
     async getUserList(roomId) {
      return new Promise(async (resolve, reject) => {
        try {
          const data = await this.imSDK.getUserList(roomId)

          resolve(data)
        } catch (error) {
          reject(error)
        }
      })
    }

    /**
     * 改名
     * @returns
     */
    async updateNickAndAvatar(options = {}) {
      const { nickname } = options
      return new Promise(async (resolve, reject) => {
        try {
          this.imSDK.updateNickAndAvatar({
            nickname,
          })
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    }

    /**
     * 获取历史消息
     * @returns
     */
    async getHistroyMsg(roomId) {
      return new Promise(async (resolve, reject) => {
        try {
          const data = await this.imSDK.getRoomHistory(roomId)
          resolve(data)
        } catch (error) {
          reject(error)
        }
      })
    }

    /**
     * 事件监听
     */
    onEvent(event, fn) {
      this.imSDK.on(event, fn)
    }

    /**
     * 卸载事件
     */
    offEvent(event, fn) {
      this.imSDK.off(event, fn)
    }



}
export default IM;
